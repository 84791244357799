// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/study_package/btn_close.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp-image-dialog{background:rgba(0,0,0,.8);position:fixed;top:0;left:0;right:0;bottom:0;width:100%;height:100%;z-index:100}.comp-image-dialog .image_absolute{position:absolute;top:0;left:0;right:0;bottom:0;margin:auto;width:80%;height:85%;z-index:101}.comp-image-dialog .image_absolute .image_relative{text-align:center;vertical-align:middle;position:relative}.comp-image-dialog .image_absolute .image_relative img{width:95%}.comp-image-dialog .image_absolute .image_relative .btn-close{position:absolute;right:-10px;top:-15px;width:32px;height:32px;color:#fff;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:32px;z-index:102}@media screen and (min-width:1024px){.comp-image-dialog .image_absolute .image_relative img{width:70%}}@media screen and (min-width:768px){.comp-image-dialog .image_absolute .image_relative img{width:70%}}", ""]);
// Exports
module.exports = exports;
