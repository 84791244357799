import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { print } from "../utils/helpers";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { WechatFollow } from "../study_package_activities/WechatFollow";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import {
  MemberCardRechargeDialog,
  MemberCardRechargeDialogProxy,
} from "../shared/MemberCardRechargeDialog";
import { ToastManager } from "../utils/Toast";
import CountDownTime from "../utils/CountDown";
import BuyExamFeature from "./BuyExamFeature";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import Raven from "raven-js";
import "./BuyExam.scss";

const PayBtn = ({ finalPrice, price, click }) => {
  let show = <small>尚无奖学金可抵扣</small>;
  if (finalPrice > 0) {
    show = (
      <small>
        抵扣后仅
        <span>{`¥${finalPrice}`}</span>
      </small>
    );
  }
  return (
    <button className="comp-pay-btn btn" onClick={click}>
      <p>报名¥{price}</p>
      {show}
    </button>
  );
};

const VirtualAccountBtn = ({ virtualAccount, price, click }) => {
  return (
    <button className="comp-pay-btn btn" onClick={click}>
      <p>奖学金报名¥{price}</p>
      <small>
        当前余额
        <strong>{`¥${virtualAccount ? virtualAccount.money : 0}`}</strong>
      </small>
    </button>
  );
};

const SharePosterBtn = ({ btnClassName, renderContent, click }) => {
  return (
    <button
      className={
        "comp-share-poster-btn btn " + (btnClassName ? btnClassName : "")
      }
      onClick={click}
    >
      {renderContent()}
    </button>
  );
};
const StartExam = ({ click }) => {
  return (
    <button className="comp-start-exam btn" onClick={click}>
      <p>开始答题</p>
    </button>
  );
};
const ReadReport = ({ click }) => {
  return (
    <button className="comp-read-report btn" onClick={click}>
      <p>结果解读</p>
    </button>
  );
};
const BuyState = {
  notBought: "notBought",
  bought: "bought",
  hadRight: "hadRight",
  hadExamed: "hadExamed",
  reNewExam: "reNewExam",
};

const BuyMode = {
  virtual_account: 1,
};
const BuyExam = (props) => {
  print(props);
  const {
    qrcode_url,
    max_invite_users_count,
    bought,
    had_right,
    had_examed,
    last_exam_time,
    consume_right_url,
    user_promotion_activity,
    dimension_examination,
    standalone,
    kb_dimension_url,
    report_url,
    virtual_account,
    buy_mode,
  } = props;
  let isAtLastExamDay = false;
  if (last_exam_time) {
    const lastExamMonth = new Date(last_exam_time).getMonth();
    const lastExamDate = new Date(last_exam_time).getDate();
    const nowMonth = new Date().getMonth();
    const nowDate = new Date().getDate();
    if (lastExamMonth === nowMonth && lastExamDate === nowDate) {
      isAtLastExamDay = true;
    }
  }

  let bottomState = BuyState.notBought;
  let isShowCountDown = false;
  if (had_examed) {
    bottomState = BuyState.hadExamed;
    isShowCountDown = true;
    if (!last_exam_time || isAtLastExamDay) {
      bottomState = BuyState.reNewExam;
      isShowCountDown = false;
    }
  } else if (had_right) {
    bottomState = BuyState.hadRight;
  } else if (bought) {
    bottomState = BuyState.bought;
  }

  const [state, setState] = useState({
    isFollowWechat: false,
    bottomBarState: bottomState,
    isShowCountDown: isShowCountDown,
  });

  print("state", state);
  const alertQRCode = (e) => {
    print("alertQRCode");
    setState((preState) => {
      return {
        ...preState,
        isFollowWechat: !preState.isFollowWechat,
      };
    });
  };

  const calcPrice = ({ price, virtual_account }) => {
    let money = virtual_account ? virtual_account.money : 0;
    let finalPrice = price - money;
    if (finalPrice < 0) {
      return 0;
    } else {
      return finalPrice;
    }
  };

  const finalPrice = calcPrice(dimension_examination.price, virtual_account);

  const onBuy = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Users", "Buy", "DimesionExamination");
    }

    if (qrcode_url) {
      alertQRCode();
      return;
    }

    const dialogProxy = new MemberCardRechargeDialogProxy({
      ...props,
      price: dimension_examination.price,
      name: dimension_examination.name,
    });
    dialogProxy.open();

    // const toast = ToastManager.showLoading("提交中...");
    // request({
    //   url: buyUrl,
    //   data: {
    //     price: dimensionExamination.price
    //   },
    //   method: 'POST',
    //   headers: csrfHeaders,
    // }).then((res) => {
    //   toast.cancel()
    //   if (res.data.err_code === 200) {
    //     setState((preState)=>{
    //       return {
    //         ...preState,
    //         bottomState: BuyState.bought
    //       }
    //     })
    //   }
    // }).catch((err) => {
    //   Raven.captureException(err)
    //   toast.cancel()
    // })
  };

  const visitedClick = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Users", "Free", "DimesionExamination");
    }

    if (qrcode_url) {
      alertQRCode();
      return;
    }

    let free = "invited_for_free_exam";
    if (state.bottomBarState === BuyState.hadExamed) {
      free = "inviting_friend_for_money";
    }

    let sharePosterProxy = new SharePosterProxy({
      posterUrl: props.poster_url,
      posterImgSrc: props.poster_img_src,
      posterTemplate: props.poster_photo_template_url,
      posterCreation: props.poster_creation,
      mode: "front_end",
      free: free,
      maxInviteUsersCount: props.max_invite_users_count,
    });

    sharePosterProxy.open();
  };

  const goToReport = (e) => {
    if (qrcode_url) {
      alertQRCode();
      return;
    }
    window.location.href = report_url;
  };

  const goToExam = (e) => {
    print("开始测评");
    if (qrcode_url) {
      alertQRCode();
      return;
    }
    let confirmDialog;
    if (state.bottomBarState === BuyState.hadRight) {
      const buttons = [
        {
          label: "取消",
          onClick: () => {
            confirmDialog.close();
          },
        },
        {
          label: "确定",
          onClick: () => {
            confirmDialog.close();
            const toast = ToastManager.showLoading("提交中...");
            request({
              url: consume_right_url,
              data: {
                user_promotion_activity_id: user_promotion_activity.id,
              },
              method: "POST",
              headers: csrfHeaders,
            })
              .then((res) => {
                toast.cancel();
                print(res.data.err_code);
                if (res.data.err_code === 200) {
                  setState((preState) => {
                    return {
                      ...preState,
                      bottomBarState: BuyState.bought,
                    };
                  });
                }
              })
              .catch((err) => {
                Raven.captureException(err);
                toast.cancel();
              });
          },
        },
      ];
      confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>确定使用1次兑换权益，兑换本次测评吗？</Dialog>
      );
    } else {
      const buttons = [
        {
          label: "取消",
          onClick: () => {
            confirmDialog.close();
          },
        },
        {
          label: "确定",
          onClick: () => {
            confirmDialog.close();
            window.location.href = kb_dimension_url;
          },
        },
      ];
      confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          <LazyloadContent content={dimension_examination.final_note} />
        </Dialog>
      );
    }
  };

  const virtualAccountBuyClick = (e) => {
    if (
      !virtual_account ||
      virtual_account.money < dimension_examination.price
    ) {
      let free = "virtual_count_not_enough";
      let sharePosterProxy = new SharePosterProxy({
        posterUrl: props.poster_url,
        posterImgSrc: props.poster_img_src,
        posterTemplate: props.poster_photo_template_url,
        posterCreation: props.poster_creation,
        mode: "front_end",
        free: free,
        maxInviteUsersCount: props.max_invite_users_count,
      });

      sharePosterProxy.open();
    } else {
      onBuy(e);
    }
  };

  const handleCountdownEnd = (e) => {
    setState((preState) => {
      return {
        ...preState,
        isShowCountDown: !preState.isShowCountDown,
      };
    });
  };

  const bottomExam = () => {
    switch (state.bottomBarState) {
      case BuyState.notBought:
        let leftBtn = (
          <PayBtn
            finalPrice={finalPrice}
            price={dimension_examination.price}
            click={onBuy}
          />
        );
        if (buy_mode == BuyMode.virtual_account) {
          leftBtn = (
            <VirtualAccountBtn
              virtualAccount={virtual_account}
              price={dimension_examination.price}
              click={virtualAccountBuyClick}
            />
          );
        }
        return (
          <div className="buy-exam-bottom">
            {leftBtn}
            <SharePosterBtn
              renderContent={() => {
                return (
                  <React.Fragment>
                    <p>邀请免费测</p>
                    <small>最多{max_invite_users_count}人关注即可</small>
                  </React.Fragment>
                );
              }}
              click={visitedClick}
            />
          </div>
        );
      case BuyState.hadRight:
      case BuyState.bought:
        return (
          <div className="buy-exam-bottom single">
            <StartExam click={goToExam} />
          </div>
        );
      case BuyState.hadExamed:
        return (
          <div className="buy-exam-bottom">
            <SharePosterBtn
              btnClassName={"invited-friend"}
              renderContent={() => {
                return (
                  <React.Fragment>
                    <p>邀请好友</p>
                    <small>赚取奖学金</small>
                  </React.Fragment>
                );
              }}
              click={visitedClick}
            />
            <ReadReport click={goToReport} />
          </div>
        );
      case BuyState.reNewExam:
        return (
          <div className="buy-exam-bottom">
            <SharePosterBtn
              btnClassName={"invited-friend"}
              renderContent={() => {
                return <p>重新答题</p>;
              }}
              click={goToExam}
            />
            <ReadReport click={goToReport} />
          </div>
        );
    }
  };

  useEffect(() => {
    const share_url = props.share.url;
    const share_image_url = props.share.image_url;
    var title = props.share.title;
    var desc = props.share.description;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }, []);

  return (
    <div className="comp-buy-exam">
      <BuyExamFeature {...dimension_examination} standalone={standalone} />
      <div className="buy-exam-bottom-fixed">
        {state.isShowCountDown && last_exam_time && (
          <div className="buy-exam-top">
            <CountDownTime
              endTime={new Date(last_exam_time)}
              overText=""
              onEnd={handleCountdownEnd}
            >
              {({ d, h, m, s }) => {
                return <span>距离下次测评：{d}天 </span>;
              }}
            </CountDownTime>
          </div>
        )}
        {bottomExam()}
      </div>
      {state.isFollowWechat && (
        <WechatFollow alertQRCode={alertQRCode} qrcodeUrl={qrcode_url} />
      )}
    </div>
  );
};

export default BuyExam;
