import React from "react";
import styled from "styled-components";

import iconClose from "study_package/btn_close.png";
import imageCampInvitePopup from "study_package/camp_invite_popup.png";

const StyledUserBoughtSuccessDialog = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 990;
  padding: 0px;
  overflow-y: scroll;
  .user_bought_success_dialog {
    background: none;
    .btn-close {
      position: absolute;
      right: 0;
      top: -35px;
      width: 30px;
      height: 30px;
    }

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    border-radius: 20px 20px 16px 16px;
    padding-bottom: 20px;

    .img-camp-invite-popup {
      width: 100%;
    }
  }
`;

export default class CampInvitePopup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledUserBoughtSuccessDialog>
        <div className="user_bought_success_dialog">
          <img class="btn-close" src={iconClose} onClick={ () => { this.props.onModalClose() } } />
          <img class="img-camp-invite-popup" src={imageCampInvitePopup} />
        </div>
      </StyledUserBoughtSuccessDialog>
    );
  }
}
