// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/study_package/icon_package_placeholder.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".wrong_quiz_practice_result_container{width:100%;padding-bottom:20px;overflow-y:scroll;background:#fff}.wrong_quiz_practice_result_container .practice_new_report_box{margin-top:8px}.wrong_quiz_practice_result_container .practice_new_report_box:first-child{margin-top:15px}.wrong_quiz_practice_result_container .none_quizzes{margin-top:60px;text-align:center;font-size:14px}.wrong_quiz_practice_result_container .practice_report_box{margin-top:8px}.wrong_quiz_practice_result_container .quizzes_count{margin-top:15px;margin-bottom:10px;margin-left:15px;color:#333;font-size:16px;font-weight:700}.markdown_content{font-size:23px;padding:0 7% 20px;color:#756855}.none_content{padding-top:90px;margin:0 auto}.none_content .icon{position:relative;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:263px;height:147px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%}.none_content .text{text-align:center;color:#444;font-size:16px;line-height:1em;margin-top:10px}.none_content .button{position:relative;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);margin-top:40px;width:200px;height:50px;line-height:50px;font-size:16px;text-align:center;color:#fff;background-color:#6ad33b;border-radius:25px}", ""]);
// Exports
module.exports = exports;
