/* eslint-disable react/prop-types */
import React, { Component } from "react";
import styled from "styled-components";
import ImageDialogProxy from "../../shared/ImageDialog";
import "./StudyReportBar.scss";
import banner_course from "../../../../assets/images/banner_course.png";

const StyledReadingStudyProgress = styled.div`
  margin: 27px 0px 17px 0px;
  /* margin-top: 13px; */
  width: 100%;
  background: ${({ type }) =>
    type === "true"
      ? "#fff"
      : "linear-gradient(135deg, #5fc8fc 0%, #54b3f8 100%)"};
  border-radius: ${({ type }) => (type === "true" ? "15px" : "5px")};
  display: flex;
  flex-direction: column;
  box-shadow: ${({ type }) =>
    type === "true" ? "0px 3px 10px rgba(255, 151, 60, 0.5)" : ""};
  padding: ${({ type }) => (type === "true" ? "10px 15px" : "")};
  .counts {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 0 12px 0;
    div {
      flex: 1;
    }
    .center {
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      border-right: 1px solid rgba(255, 255, 255, 0.4);
    }
    .common {
      .top {
        color: #ffffff;
        font-weight: 500;
        font-size: 13px;
        line-height: 1em;
        padding-bottom: 5px;
        .number {
          padding: 0 2px 0 0;
          font-size: 20px;
          font-weight: bold;
          color: #ffe75f;
        }
        .name {
          position: relative;
          top: -2px;
        }
      }
      .title {
        position: relative;
        top: 4px;
        color: #ffffff;
        font-weight: 500;
        font-size: 13px;
        line-height: 1em;
      }
    }
  }
  .progress {
    margin: 10px 20px 18px 20px;
    border-top: 1px dashed rgba(255, 255, 255, 0.4);
    p {
      font-size: 13px;
      color: #ffffff;
      margin-top: 15px;
    }
    .study_progress {
      margin-top: 12px;
      width: 100%;
      height: 5px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 200px;
      position: relative;
      .learned_progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 200px;
        background: #ffffff;
      }
    }
  }
`;

export default class StudyReportInfo extends Component {
  showReward = (e) => {
    const newPhotoUrl =
      "https://upload.fireflybaby.cn/study_package_activity/a457eba350b8684313fee2ae2a8d3af3.jpg";
    const photoUrl =
      this.props.package_mode == 0
        ? this.props.reward.note_photo_url
        : newPhotoUrl;
    new ImageDialogProxy({ imgSrc: photoUrl, transition: true }).open();
  };

  completeRecord = () => {
    if (!this.props.reward) {
      return "";
    }
    const {
      media_lessons_count,
      day_studies_count,
      photo_url,
      end_time,
    } = this.props.reward;
    let finishCourseType;
    if (media_lessons_count) {
      finishCourseType = `${media_lessons_count}节课`;
    } else if (day_studies_count) {
      finishCourseType = `${day_studies_count}天`;
    }

    if (this.props.isReading) {
      return (
        <div className="complete_reward reading_study_report">
          <div className="complete_reward_desc">
            截止{end_time}，完成
            {finishCourseType}
            学习，即可获得
            <a href="javascript: void(0);" onClick={this.showReward}>
              完课奖励
            </a>
          </div>
          {/* {photo_url && (
            <div className="reward_image">
              <img src={photo_url}></img>
            </div>
          )} */}
        </div>
      );
    }

    return (
      <div className="complete_reward">
        <div className="complete_reward_title">
          精美奖牌、奖状，只给按时完课的你!
        </div>
        <div className="complete_reward_desc">
          截止{end_time}，完成
          {finishCourseType}
          学习，即可获得
          <a href="javascript: void(0);" onClick={this.showReward}>
            完课奖励
          </a>
        </div>
        {/* {photo_url && (
          <div className="reward_image">
            <img src={photo_url}></img>
          </div>
        )} */}
      </div>
    );
  };

  render() {
    const day_studies_judge =
      this.props.reward && this.props.reward.day_studies_count;
    const media_lessons_judge =
      this.props.reward && this.props.reward.media_lessons_count;
    const { isReading, readingArticlesCount, readingWordsCount } = this.props;
    const currentStudyModule = this.props.currentStudyModule || {};
    const {
      studied_day_studies_count,
      total_day_studies_count,
      name,
    } = currentStudyModule;
    const calcAlreadyStudyDayPercent = (
      studied_course_day_count,
      course_day_count
    ) => {
      // const { studied_course_day_count, course_day_count } = study_package_activity;
      const widthPercent =
        (studied_course_day_count / course_day_count) * 100 + "%";
      return widthPercent;
    };
    let unitName, progress;
    if (isReading) {
      unitName = name.replace(/\n/, "").replace(/\\s*/g, "");
      progress = (
        (studied_day_studies_count / total_day_studies_count).toFixed(2) * 100
      ).toFixed(0);
    }
    console.log('study_report_info', this.props);
    return (
      <div className="study_report_info_bg">
        {/* {this.completeRecord()} */}
        {isReading && (
          <StyledReadingStudyProgress type={this.props.type ? "true" : "false"}>
            <div className="counts">
              <div className="reading_days common" style={{ borderRight: this.props.study_mode_version === 4 || this.props.study_mode_version === 5 ? '1px solid #ddd' : 'none' }}>
                <div className="top">
                  <span className="number">{studied_day_studies_count}</span>
                  <span className="name">天</span>
                </div>
                <p className="title">坚持阅读</p>
              </div>
              {(this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5) &&
                <div className="reading_articles center common">
                  <div className="top">
                    <span className="number"> {readingArticlesCount}</span>
                    <span className="name">篇</span>
                  </div>
                  <p className="title">累计阅读篇数</p>
                </div>
              }
              <div className="reading_words common">
                <div className="top">
                  <span className="number">{readingWordsCount}</span>
                  <span className="name">字</span>
                </div>
                <p className="title">累计阅读字数</p>
              </div>
            </div>
            <div className="progress">
              {this.props.study_mode_version === 4 || this.props.study_mode_version === 5 ?
                <p>{`《${this.props.book_name}》阅读进度${progress}%`}</p>
                :
                <p>{`《${unitName}》单元进度${progress}%`}</p>
              }
              <div className="study_progress">
                <div
                  className="learned_progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </StyledReadingStudyProgress>
        )}
        {!isReading && (
          <div
            className={`card_day_period_statistics ${
              this.props.reward ? "card_day_period_triangle" : ""
            }`}
          >
            <div
              className="card_day "
              // className={`card_day ${
              //   day_studies_judge ? "left_inverted_triangle" : ""
              // }`}
            >
              <p>
                {/* {this.props.studiedCourseDayCount}
                <i>/{this.props.courseDayCount}</i> */}
                {this.props.totalStudiedMediaLessonsCount}
                <i>/{this.props.mediaLessonsCount}</i>
              </p>
              <div className="course_study_progress">
                <div className="left_progress">
                  <div className="progress_bar">
                    <div
                      className="progress"
                      style={{
                        // width: "50%",
                        width: calcAlreadyStudyDayPercent(
                          this.props.totalStudiedMediaLessonsCount,
                          this.props.mediaLessonsCount
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="header-right-top-desc">
                    <span>累计已学课时</span>
                    {/* <span>{this.props.leftSectionName}</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ width: "1px", height: "45px", backgroundColor: "#fff" }}
            ></div>
            <div
              className="card_period"
              // className={`card_period ${
              //   media_lessons_judge ? "right_inverted_triangle" : ""
              // }`}
            >
              <p>
                {this.props.studied_tags_count || 0}
                <i>/{this.props.total_tags_count || 0}</i>
              </p>
              <div className="course_study_progress">
                <div className="left_progress">
                  <div className="progress_bar">
                    <div
                      className="progress"
                      style={{
                        // width: "50%",
                        width:
                          this.props.studied_tags_count == 0
                            ? 0
                            : calcAlreadyStudyDayPercent(
                                this.props.studied_tags_count,
                                this.props.total_tags_count
                              ),
                      }}
                    ></div>
                  </div>
                  <div className="header-right-top-desc">
                    <span>累计已做题型</span>
                  </div>
                </div>
              </div>
              {/* <span>{this.props.rightSectionName}</span> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}
