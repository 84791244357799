import React from "react";
import UserAgent from "../utils/UserAgent";
import { request, csrfHeaders } from "../utils/request";
import { promptPageBack } from "../utils/helpers";
import CountDownTime from "../utils/CountDown";
import { Dialog, DialogManager } from "../utils/Dialog";
import { ReportQuizItem } from "../utils/report_quiz_item/ReportQuizItem";
import { BasicQuizGeneration } from "../day_studies/BasicQuizGeneration";
import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";
import {
  EffectAudioGroup,
  ShowQuestionPhotoUrl,
  ShowInputArea,
  ShowOptionsArea,
  ConfirmButton,
  ImgDialog,
  SetCanScrollIcon,
} from "../utils/quizzes_practice_template/practiceTemplates.jsx";
import ComprehensiveWrongQuizPractise from "./ComprehensiveWrongQuizPractise";
import "../day_studies/GenerateQuiz.scss";
// 结果页样式
import "./WrongQuizPractise.scss";
import ReactMarkdown from "react-markdown";

const NoneContent = () => {
  return (
    <div className="none_content">
      <div className="icon"></div>
      <div className="text">暂无错题</div>
    </div>
  );
};

const ResultPage = ({ mediaLessonsResult }) => {
  console.log(mediaLessonsResult);
  const ScreenHeight = document.body.clientHeight;
  return (
    <div
      className="wrong_quiz_practice_result_container"
      style={{
        maxHeight: ScreenHeight,
        height: ScreenHeight,
      }}
    >
      {mediaLessonsResult.map((mediaLesson) => {
        return mediaLesson.quizzes.map((quiz, iddx) => (
          <ReportQuizItem
            quiz={quiz}
            iddx={iddx}
            key={iddx}
            needIndex={false}
            needAnsweredStatus={true}
          />
        ));
      })}
    </div>
  );
};

class WrongQuizPracticeItem extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    // var countDownTime = 0
    let quiz = this.initRecoverRecord.call(this, props.quizzes);
    this.state = {
      quiz: quiz,
      answers: [],
      effectSoundURL: "",
      answerText: "",
      isSuccDisplayQuizImages: true,
      imgDialogStatus: false,
    };
    this.initDataToBackEnd();
    // this.confirmSelect = this.confirmSelect.bind(this)
  }
  initDataToBackEnd = () => {
    this.answer = {};
    this.quizIds = this.props.quizzes.reduce((initialQuiz, nextQuiz) => {
      initialQuiz.push(nextQuiz.id);
      return initialQuiz;
    }, []);
    this.mediaLessons = this.props.quizzes.reduce((initialMDS, nextQuiz) => {
      if (
        nextQuiz.media_lesson &&
        initialMDS.findIndex(
          (mds) => mds && mds.id === nextQuiz.media_lesson.id
        ) == -1
      ) {
        let group = {
          id: nextQuiz.media_lesson.id,
          quizzes: [],
        };
        initialMDS.push(group);
      }
      return initialMDS;
    }, []);
  };

  handleFilledAnswerData(isAnswerCorrect, mediaLessonId) {
    // TODO
    this.mediaLessonId = mediaLessonId;
    const { quiz, answerText } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    console.log(
      subquestions[0].answer,
      "---",
      answerText,
      "---",
      mediaLessonId
    );
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: 0,
              input_answer: answerText.trim(),
              correct_answer: subquestions[0].answer,
              correct: isAnswerCorrect,
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: isAnswerCorrect,
            input_answer: answerText.trim(),
            correct_answer: subquestions[0].answer,
            question_type: 0,
          },
        },
      },
    };
  }

  handleSelectedAnswerData(correctOption, option, mediaLessonId) {
    this.mediaLessonId = mediaLessonId;
    const userAnswer = { ...option };
    const { quiz } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;

    console.log(correctOption, "---", userAnswer, "---", mediaLessonId);
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: subquestions[0].question_type,
              choose_option: userAnswer,
              correct_option: correctOption,
              correct: userAnswer.correct,
              options: [...subquestions[0].options],
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
          },
        },
      },
    };
    console.dir(this.mediaLessons);
  }

  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, mediaLesson] of Object.entries(this.mediaLessons)) {
      if (mediaLesson.id === this.mediaLessonId) {
        let idx = mediaLesson.quizzes.findIndex(
          (quiz) => quiz.id === this.state.quiz.id
        );
        mediaLesson.quizzes.splice(idx, 1);
        break;
      }
    }
  };

  submitInterviewWrongQuizAnswers = () => {
    var data = {
      exam_type: this.props.exam_type,
      quiz_ids: this.quizIds,
      kb_lessons: this.mediaLessons,
      total_time: this.totalCostTime,
      ...this.calculateCorrectness.call(this, this.mediaLessons),
    };
    console.log(218, data);
    console.log(219, this.props.save_result_url);
    if (!this.props.save_result_url) {
      alert("缺少save_result_url字段");
      return;
    }
    return request({
      url: this.props.save_result_url,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    });
  };

  showResultPage() {
    if (UserAgent.isWebBrowser()) {
      window.onpopstate = function () {
        promptPageBack("WRONGQUIZPushStack").clear();
      };
    }
    return function (rsp) {
      console.log(rsp);
      if (rsp) {
        this.setState({
          showResult: true,
          mediaLessonsResult: rsp.data.kb_lessons,
        });
      }
    };
  }

  handleCountdownEnd = (e) => {
    const buttons = [
      {
        label: "确定",
        onClick: () => {
          this.confirmDialog.close();
          this.submitAnswersAndshowResult.call(this);
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>本次考试时间已到</Dialog>
    );
  };

  collectQuizzesModule = (mediaLessonsResult) => {
    const arr = [];
    let module_collection = [];
    mediaLessonsResult.forEach((lesson, index) => {
      arr.push(lesson.quizzes);
    });
    module_collection = arr.flat(Infinity);
    return module_collection;
  };

  render() {
    if (this.props.quizzes.length === 0) {
      return <NoneContent />;
    }

    const options = this.state.quiz.subquestions[0].options;

    const { quiz_audio_enable } = this.props;

    const {
      isSelectedOrFilled,
      quiz,
      selectedIndex,
      isSuccDisplayQuizImages,
      showResult,
      mediaLessonsResult,
      answerText,
      imgDialogStatus,
      setCanScrollIcon,
    } = this.state;

    const index = this.props.quizzes.findIndex(function (element) {
      return element.id == quiz.id;
    });

    let isOptionsImageExit;
    if (quiz.subquestions[0].options) {
      isOptionsImageExit = quiz.subquestions[0].options.some((option) => {
        return option.photo;
      });
    }
    // console.log("WrongQuizPractice: ", isOptionsImageExit);

    return [
      !showResult && (
        <div
          className="comp-wrong-quizzes-exam"
          id="comp_grade_exam"
          ref={(comp) => (this.compGrade = comp)}
          key="comp-interview-exam"
        >
          <EffectAudioGroup />
          <div className="generate_quiz_box">
            {setCanScrollIcon && <SetCanScrollIcon />}
            <div className="question_img_container">
              <div className="quiz_and_read_quiz">
                {quiz.question_audio_url && quiz_audio_enable && (
                  <QuizVoice
                    audioUrl={quiz.question_audio_url}
                    quizId={quiz.id}
                  />
                )}
                <div className="quiz_length new_quiz_length">
                  <div className="quiz_number">
                    <i>{`第${index + 1}题`}</i>/
                    {`共${this.props.quizzes.length}题`}
                  </div>
                </div>
              </div>
              <div className="question_and_photo">
                {quiz.question && (
                  <div className="quiz_content_box">{quiz.question}</div>
                )}
                {quiz.question_body ? (
                  <ReactMarkdown
                    source={quiz.question_body}
                    className={"markdown_content"}
                    escapeHtml={false}
                  ></ReactMarkdown>
                ) : (
                  ""
                )}
                <ShowQuestionPhotoUrl
                  isSuccDisplay={isSuccDisplayQuizImages}
                  questionPhotoUrl={quiz.question_photo_url}
                  reloadQuizImages={this.reloadQuizImages}
                  handleImgDialog={() => this.handleImgDialog(true)}
                />
              </div>
              {imgDialogStatus && (
                <ImgDialog
                  photo_url={quiz.question_photo_url}
                  handleImgDialog={() => this.handleImgDialog(false)}
                />
              )}
            </div>
            {quiz.subquestions[0].question_type === 1 ? (
              <ShowOptionsArea
                isSuccDisplay={isSuccDisplayQuizImages}
                options={options}
                isSelectedOrFilled={isSelectedOrFilled}
                selectedIndex={selectedIndex}
                handleSelect={this.handleSelect}
                isOptionsImageExit={isOptionsImageExit}
                confirmButton={() => (
                  <ConfirmButton
                    confirmSelect={this.confirmSelect}
                    isSelectedOrFilled={isSelectedOrFilled}
                    key="button"
                  />
                )}
              />
            ) : (
              <ShowInputArea
                isSuccDisplay={isSuccDisplayQuizImages}
                answerText={answerText}
                isSelectedOrFilled={isSelectedOrFilled}
                handleInputChange={this.handleInputChange}
                btnSubmitFillAnwer={this.btnSubmitFillAnwer}
              />
            )}
          </div>
        </div>
      ),

      !!showResult &&
        mediaLessonsResult.length > 0 &&
        (this.props.exam_type == "WRONG_QUIZ_REVISAL" ? (
          <ComprehensiveWrongQuizPractise
            allQuizzes={this.collectQuizzesModule(mediaLessonsResult)}
            setsUrl={this.props.get_wrong_quiz_sets_url}
            key="comprehensive_result"
          />
        ) : (
          <ResultPage
            showResult={showResult}
            mediaLessonsResult={mediaLessonsResult}
            key="result"
          />
        )),
    ];
  }
}

export default class WrongQuizPractise extends React.Component {
  constructor(props) {
    super(props);
    this.wrappedGradeExam = BasicQuizGeneration(
      WrongQuizPracticeItem,
      props.exam_type
    );
  }
  componentDidMount() {
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack("WRONGQUIZPushStack", "要退出本次练习吗？").setPrompt();
    }
  }
  render() {
    const WrappedGradeExam = this.wrappedGradeExam;
    return <WrappedGradeExam {...this.props} />;
  }
}
