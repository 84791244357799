import React from "react";
import PropTypes from "prop-types";

import ReactMarkdown from "react-markdown";

import "./InterviewSchools.scss";

export default class InterviewSchools extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    let objR = new Object();
    this.props.schools.forEach(function (item) {
      objR[item.id] = true;
    });

    this.state = {
      schools: this.props.schools,
      list: objR,
    };
  }
  handleClick(e) {
    e.preventDefault();
    let ind = e.currentTarget.getAttribute("data-index");
    let objR = new Object();
    this.props.schools.forEach(function (item) {
      objR[item.id] = true;
    });
    console.log(objR);
    this.state.list[ind] = !this.state.list[ind];
    this.setState({
      list: this.state.list,
    });
  }

  componentDidMount = () => {
    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    var title = "幼升小面试真题 | 2017-2011年上海数学思维面试真题汇编";
    var desc = "还有基于真题测评结果的针对性练习题~";
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  };

  render() {
    const { schools, list } = this.state;

    return (
      <div className="comp-interview-schools">
        {schools.map((school) => (
          <div className="containe" key={school.id}>
            <div
              className="school_title_box"
              onClick={this.handleClick.bind(this)}
              data-index={school.id}
            >
              <span>{list[school.id] ? "+ 展开" : "- 折叠"}</span>
              <div className="title">{school.name}</div>
            </div>
            {list[school.id] ? (
              ""
            ) : (
              <div className="content">
                <ReactMarkdown source={school.real_quiz} />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
