// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/icon_quick_back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".quick_back{position:fixed;bottom:25px;left:0;z-index:1000;width:90px;background:#50bffb;text-align:center;padding:0 0 0 15px;border-radius:0 5px 5px 0;color:#fff;background-color:rgba(0,0,0,.5);font-size:12px;height:30px;line-height:30px;font-weight:300}.quick_back:before{content:\"\";display:block;position:absolute;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:12px;left:5px;top:0;height:100%;background-position:0;width:14px}", ""]);
// Exports
module.exports = exports;
