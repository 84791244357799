import React, { useState, useEffect } from "react";
import { debounce } from "../utils/helpers";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import Raven from "raven-js";
import comp_home_left from "examinations/question_home_bottom_left.png";
import comp_home_right from "examinations/question_home_bottom_right.png";
import "./ThinkingExam.scss";

const ThinkingExam = ({ ...props }) => {
  const { questions, thinking_exam_result_url } = props;
  const [optionId, setOptionId] = useState("");
  const [activeButton, setActiveButton] = useState(false);
  const question = questions ? questions[0] : [];

  const coverQuestionnaire = () => {
    const confirmCalculationClick = (e) => {
      let toast = ToastManager.showLoading("提交中...");
      request({
        url: thinking_exam_result_url,
        method: "POST",
        headers: csrfHeaders,
        data: {
          question_id: question?.id,
          option_ids: [optionId],
        },
      })
        .then((resp) => {
          toast.cancel();
          const result = resp.data;
          console.log("submitAnswer_result: ", result);
          if (!!result?.examination_url) {
            window.location.href = result.examination_url;
          }
        })
        .catch((err) => {
          toast.cancel();
          Raven.captureException(err);
        });
    };
    const handleQuestionsOptionClick = (e) => {
      const option_id = e.currentTarget.getAttribute("data-option-id");
      setActiveButton(true);
      setOptionId(option_id);
    };

    return (
      <div className="grade_exam_content">
        <div className="title">{question?.name}</div>
        <div className="describe_button">
          {question?.options.map((item, index) => (
            <div
              key={index}
              className={`option_hit ${
                optionId == item.id ? "active_option" : ""
              }`}
              data-option-id={item.id}
              onClick={handleQuestionsOptionClick}
            >
              <span className="option">{item.option}</span>
              <span className="hint">{item.hint}</span>
            </div>
          ))}
          <div
            className={`confirm_btn ${activeButton ? "" : "disabled"}`}
            onClick={activeButton ? debounce(confirmCalculationClick) : null}
          >
            确定
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="comp-grade-exam-child">
      <div className="grade-exam-cover">
        <div className="exam_section">
          <div className="grade_exam_top"></div>
          {coverQuestionnaire()}
          <div className="grade_exam_bottom">
            <div className="photo_section">
              <img src={comp_home_left} alt="" />
              <img src={comp_home_right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThinkingExam;
