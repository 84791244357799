import React from "react";
import PropTypes from "prop-types";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import SolveIdea from "../utils/study_report_info/SolveIdea";
import { ToastManager } from "../utils/Toast";
import ReactMarkdown from "react-markdown";
import RenderButtom from "../day_studies/courses/common/RenderButtom";
import {
  ShowQuestionPhotoUrl,
  ImgDialog,
} from "../utils/quizzes_practice_template/practiceTemplates.jsx";
import { request, csrfHeaders } from "../utils/request";
import { retryFetch, debounce, promptPageBack } from "../utils/helpers";
import CountDownDate from "../utils/count_down_date/CountDownDate";
import { Dialog, DialogManager } from "../utils/Dialog";
import AudioPlayer from "../utils/audio_player/AudioPlayer";
import {
  CorrectPinyinVoice,
  ComparePinyinVoice,
} from "../day_study_reports/StudyReport";
import URI from "jsuri";
import { BasicQuizGeneration } from "../day_studies/BasicQuizGeneration";
import {
  SetCanScrollIcon,
  ShowInputArea,
  ShowOptionsArea,
} from "../utils/quizzes_practice_template/practiceTemplates";
import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";
import ContinusWrongModal from "./components/ContinusWrongModal.jsx";
import UserAgent from "../utils/UserAgent";
import icon_fail from "icon_quiz_fail.png";
import icon_pass from "icon_quiz_pass.png";
import doubtImg from "icon_quiz_doubt.png";
import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";
import icon_module_border from "c4/icon_module_border.png";
import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";
import icon_clock from "study_package/icon_time_white.png";
import comp_home_left from "examinations/question_home_bottom_left.png";
import comp_home_right from "examinations/question_home_bottom_right.png";
import course_right_white from "../../../assets/images/course_right_white.png";
import icon_cancle_upper from "../../../assets/images/icon_cancle_upper.png";
import icon_lock from "c4/icon_lock.png";
import "../day_studies/GenerateQuiz.scss";
import "./GradeExam.scss";
import "../day_studies/PracticeLearning.scss";

const ResultListHeader = ({ courseType, rankImg, score }) =>
  courseType && courseType === "poetry" ? (
    <div className="grade_score">
      <img
        src={rankImg}
        alt=""
        style={{ width: "180px", margin: "30px auto 10px", display: "block" }}
      />
    </div>
  ) : (
    <div className="grade_score">
      <span className="score_tip">本次得分</span>
      <span className="score">{score}</span>
    </div>
  );

const ResultListFooter = ({
  result,
  isSubmitAnswer,
  resultDisplay,
  reviewExamClick,
}) => {
  if (resultDisplay) {
    return (
      <div className="exam_again">
        {result.redirect_url ? (
          <a href={result.redirect_url}>确定</a>
        ) : (
          <a href="javascript:void(0)" onClick={reviewExamClick}>
            确定
          </a>
        )}
      </div>
    );
  }
  if (isSubmitAnswer) {
    return (
      <div className="exam_again">
        {new URI(window.location.search).hasQueryParam("day_study_exam_id") ? (
          <a href={result.redirect_url}>确定</a>
        ) : (
          <a href={result.redirect_url}>确定</a>
        )}
      </div>
    );
  }
};

export const SingleQuizList = ({ quiz, course_tip, tag }) => {
  const correct_audio_url_judge =
    quiz?.correct_audio_url != "" && quiz?.correct_audio_url != null;
  const error_audio_url_judge =
    quiz?.error_audio_url != "" && quiz?.error_audio_url != null;

  let attentionText = "";
  if (tag == "fail_quiz") {
    attentionText = <div className="err_attention">回答错误</div>;
  } else if (tag == "nt_quiz") {
    attentionText = <div className="uncompleted_attention">未答</div>;
  } else if (tag == "correct_quiz") {
    attentionText = <div className="correct_attention">回答正确</div>;
  }

  const normalAnswer = () => {
    return tag == "correct_quiz" ? (
      <div className="correct_answer quiz_answer">
        <p>
          你的答案：
          {quiz.correct_url != "" && quiz.correct_url != null ? (
            <img src={quiz.correct_url} />
          ) : (
            quiz.correct_answer
          )}
          （正确）
        </p>
      </div>
    ) : (
      <div className="fail_answer quiz_answer">
        <p className="first_p_label">
          你的答案：
          {!quiz.error_url && !quiz.error_answer ? (
            tag == "fail_quiz" ? (
              "回答超时"
            ) : tag == "nt_quiz" ? (
              "未答"
            ) : (
              ""
            )
          ) : quiz.error_url ? (
            <img src={quiz.error_url} />
          ) : (
            quiz.error_answer
          )}
          <span>（错误）</span>
        </p>
        <p>
          正确答案：
          {quiz.correct_url != "" && quiz.correct_url != null ? (
            <img src={quiz.correct_url} />
          ) : (
            quiz.correct_answer
          )}
        </p>
      </div>
    );
  };

  return (
    <div className="interview_knowledge_item">
      {quiz && (
        <div className="knowledge_pra_content">
          {attentionText}
          <div className="question_content">
            {quiz.question}
            {quiz.question_body && (
              <div className="question_body">
                <div className="markdown_content question_body_content">
                  <ReactMarkdown
                    source={quiz.question_body}
                    escapeHtml={false}
                  />
                </div>
              </div>
            )}
            {quiz.question_photo_url ? (
              <img src={quiz.question_photo_url} />
            ) : (
              ""
            )}
          </div>
          {error_audio_url_judge || correct_audio_url_judge ? (
            <>
              {error_audio_url_judge ? (
                <ComparePinyinVoice
                  quiz={quiz}
                  correct_audio_url_judge={correct_audio_url_judge}
                  error_audio_url_judge={error_audio_url_judge}
                />
              ) : (
                <CorrectPinyinVoice
                  quiz={quiz}
                  correct_audio_url_judge={correct_audio_url_judge}
                  text="你的答案"
                />
              )}
            </>
          ) : (
            normalAnswer()
          )}
          {quiz.hint && <SolveIdea hint={quiz.hint} />}
          {quiz.media_lesson &&
            quiz.media_lesson.name &&
            (quiz.media_lesson.conclusion || quiz.media_lesson.url) && (
              <div className="knowledge_item_des">
                {quiz.media_lesson.conclusion && (
                  <h3 className="title">{`《${quiz.media_lesson.name}》方法总结：`}</h3>
                )}
                <div className="knowledge_desc_content">
                  {quiz.media_lesson.conclusion && (
                    <LazyloadContent content={quiz.media_lesson.conclusion} />
                  )}
                  {quiz.media_lesson.url && (
                    <VideoIntensive url={quiz.media_lesson.url} />
                  )}
                  {course_tip && <i>{course_tip}</i>}
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export const ResultQuizzesList = ({ result, upDownList, handleUpDown }) => (
  <ul>
    {result.courses.map((course) => {
      if (
        course.correct_quizzes &&
        course.correct_quizzes[0] == null &&
        course.failed_quizzes &&
        course.failed_quizzes[0] == null &&
        course.nt_quizzes &&
        course.nt_quizzes[0] == null
      ) {
        return "";
      } else {
        return (
          <li key={course.course_id}>
            <div className="exam_item_head">
              <img src={upDownList[course.course_id] ? arrowDown : arrowUp} />
              <div
                className="exam_item_title"
                data-idx={course.course_id}
                onClick={handleUpDown}
              >
                <div
                  className={course.corrects.includes(false) ? "fail" : "pass"}
                >
                  <img
                    src={
                      course.corrects.includes(false)
                        ? icon_fail
                        : course.corrects.includes(null)
                        ? doubtImg
                        : icon_pass
                    }
                  />
                </div>
                <span className="course_name">{course.course_name} </span>
              </div>
            </div>
            {course.failed_quizzes.map((fail_quiz, idx) => {
              return (
                <div className="exam_item_container" key={idx}>
                  {upDownList[course.course_id] ? (
                    ""
                  ) : (
                    <SingleQuizList
                      quiz={fail_quiz}
                      course_tip={course.course_tip}
                      tag="fail_quiz"
                    />
                  )}
                </div>
              );
            })}
            {course.correct_quizzes.map((correct_quiz, idx) => {
              return (
                <div className="exam_item_container" key={idx}>
                  {upDownList[course.course_id] ? (
                    ""
                  ) : (
                    <SingleQuizList
                      quiz={correct_quiz}
                      course_tip={course.course_tip}
                      tag="correct_quiz"
                    />
                  )}
                </div>
              );
            })}
            {course.nt_quizzes &&
              course.nt_quizzes.length > 0 &&
              course.nt_quizzes.map((nt_quiz, idx) => {
                return (
                  <div className="exam_item_container" key={idx}>
                    {upDownList[course.course_id] ? (
                      ""
                    ) : (
                      <SingleQuizList
                        quiz={nt_quiz}
                        course_tip={course.course_tip}
                        tag="nt_quiz"
                      />
                    )}
                  </div>
                );
              })}
          </li>
        );
      }
    })}
  </ul>
);
class ShowResultList extends React.Component {
  constructor(props) {
    super(props);

    let objR = new Object();
    this.props.result
      ? this.props.result.courses.forEach(function (item) {
          objR[item.course_id] = true;
        })
      : "";

    this.state = {
      list: objR,
    };
  }

  handleUpDown = (e) => {
    e.preventDefault();
    let ind = e.currentTarget.getAttribute("data-idx");
    this.state.list[ind] = !this.state.list[ind];
    // console.log(this.state.list)
    this.setState({
      list: this.state.list,
    });
  };
  componentDidMount() {
    let resultPage = document.getElementById("result_page_box_two");
    resultPage.style.cssText =
      `width:100%;` +
      `height:100%;` +
      "background: white;" +
      `display:${this.props.resultDisplay};`;
    let container = document.getElementsByClassName("container")[0];
    container.style.cssText = "background: white;";
  }
  componentWillUnmount() {
    let container = document.getElementsByClassName("container")[0];
    container.style.cssText = "background: #f9f7ef;";
  }
  render() {
    const {
      result,
      poetry_exam_rank,
      renderShareContent,
      renderResultListFooter,
      result_desc,
    } = this.props;
    const { list } = this.state;

    return (
      <div className="result_page_box" id="result_page_box_two">
        <div className="result_page_list">
          {result.score >= 0 && (
            <ResultListHeader
              courseType={this.props.course_type}
              rankImg={poetry_exam_rank[result.score]}
              score={result.score}
            />
          )}
          {result_desc && <p className="grade_result_desc">{result_desc}</p>}
          <div className="grade_exam_list">
            {renderShareContent()}
            {result && (
              <ResultQuizzesList
                result={result}
                upDownList={list}
                handleUpDown={this.handleUpDown}
              />
            )}
          </div>
          {renderResultListFooter()}
        </div>
      </div>
    );
  }
}

const ShareContent = ({ shareInpageClick }) => (
  <div>
    <div id="sharehint-dialog">
      <div className="share-notice-container">
        <span>点击右上角分享给朋友</span>
        <span>查看测试全部结果</span>
      </div>
    </div>
    <div id="wechat_share_box">
      查看详细测试结果，请先
      <div className="btn_wechat_share" onClick={shareInpageClick}>
        分享本测试
      </div>
    </div>
  </div>
);

const VideoIntensive = ({ url }) => {
  return (
    <a className="video_intensive" href={url}>
      <span>5分钟视频精讲</span>
    </a>
  );
};

class CountDownTimeBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  getHourMinuteSecond = (seconds) => {
    this.props.getCountTime(seconds);
  };

  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  render() {
    const {
      countDownTime,
      handleCountdownEnd,
      singleQuizTime,
      count_down_secs,
      is_answer_time_limited,
      quizzes,
      audioPlayState,
      startAnswerTimer,
    } = this.props;

    const singleQuestionSecond = count_down_secs / quizzes.length;
    const calcTime = is_answer_time_limited
      ? singleQuestionSecond
      : count_down_secs;
    const time = is_answer_time_limited
      ? this.secondsToTime(singleQuestionSecond)
      : this.secondsToTime(count_down_secs);
    // console.log('time: ', time);

    // 显示默认时间
    if (!startAnswerTimer) {
      let hour = `${time.h == 0 ? "" : `${time.h}时`}`;
      let minute = `${time.m}分`;
      let second = `${time.s}秒`;
      return (
        <div className="count_down_box">
          {`还剩：${hour}${minute}${second}`}
        </div>
      );
    }

    if (countDownTime > 0) {
      return (
        <div className="count_down_box">
          还剩：
          <CountDownDate
            overText="结束考试"
            onEnd={handleCountdownEnd("WHOLE_QUIZ")}
            getHourMinuteSecond={this.getHourMinuteSecond}
            calcTime={calcTime}
            audioPlayState={audioPlayState}
          />
        </div>
      );
    }
    if (!!singleQuizTime) {
      return (
        <div className="count_down_box">
          还剩：
          <CountDownDate
            overText="00:00:00"
            onEnd={handleCountdownEnd("SINGLE_QUIZ")}
            getHourMinuteSecond={this.getHourMinuteSecond}
            calcTime={calcTime}
            audioPlayState={audioPlayState}
          />
        </div>
      );
    }
  }
}
class GradeExamItem extends React.Component {
  constructor(props) {
    super(props);
    console.log("GradeExamItem_props", props);
    // var countDownTime = 0
    // console.log(countDownTime)

    let objR = new Object();
    this.props.result &&
      this.props.result.courses &&
      this.props.result.courses.forEach(function (item) {
        objR[item.course_id] = true;
      });

    this.state = {
      quiz: props.quizzes[0],
      effectSoundURL: "",
      startExam: false,
      answerText: "",
      list: objR,
      isSuccDisplayQuizImages: true,
      isShowTimeAlert: false,
      imgDialogStatus: false,
      singleQuizCountTime: 100,
      // countDownTime: countDownTime
    };
    this.answers = [];
    this.uncompletedAnswers = [];
    this.answer = {};

    this.showTimeInterval = null;
    // this.confirmSelect = this.confirmSelect.bind(this);
  }

  initTimeOut = () => {
    this.setState(() => ({
      isShowTimeAlert: false,
    }));
    this.initSignleQuizTime();
    if (this.showTimeInterval) {
      clearInterval(this.showTimeInterval);
    }
  };

  handleSelectedAnswerData(correctOption, option) {
    let userAnswer = { ...option };
    let json;
    if (userAnswer.id === undefined) {
      json = {
        id: this.state.quiz.id,
        subquestions: [
          {
            id: this.state.quiz.subquestion.id,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        ],
        correct: false,
        product_code: this.state.quiz?.product_code
          ? this.state.quiz?.product_code
          : "product_code",
      };
    } else {
      json = {
        id: this.state.quiz.id,
        subquestions: [
          {
            id: this.state.quiz.subquestion.id,
            option: [{ id: userAnswer.id }],
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        ],
        correct: userAnswer.correct,
        product_code: this.state.quiz?.product_code
          ? this.state.quiz?.product_code
          : "product_code",
      };
    }

    let answer = {
      id: this.props.dimension_examination.id,
      exam_type: this.props.exam_type,
      media_lesson: {
        id: this.state.quiz?.current_lesson?.id,
        tag: {
          quiz: {
            id: this.state.quiz.id,
            input_correct:
              userAnswer.correct === undefined ? false : userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
          },
        },
      },
    };
    this.answer = answer;

    for (const [k, v] of Object.entries(json)) {
      if (v == "product_code") delete json[k];
    }
    this.answers.push(json);
  }

  handleFilledAnswerData() {
    let json;
    if (this.state.answerText.trim() === "") {
      json = {
        id: this.state.quiz.id,
        subquestions: [
          {
            id: this.state.quiz.subquestion.id,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        ],
        answer: "",
        correct: false,
        product_code: this.state.quiz?.product_code
          ? this.state.quiz?.product_code
          : "product_code",
      };
    } else {
      json = {
        id: this.state.quiz.id,
        subquestions: [
          {
            id: this.state.quiz.subquestion.id,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        ],
        answer: this.state.answerText.trim(),
        correct:
          this.state.answerText.trim() == this.state.quiz.subquestion.answer,
        product_code: this.state.quiz?.product_code
          ? this.state.quiz?.product_code
          : "product_code",
      };
    }

    let answer = {
      id: this.props.dimension_examination.id,
      exam_type: this.props.exam_type,
      media_lesson: {
        id: this.state.quiz?.current_lesson?.id,
        tag: {
          quiz: {
            id: this.state.quiz.id,
            input_correct:
              this.state.answerText.trim() ==
              this.state.quiz.subquestion.answer,
            input_answer: this.state.answerText.trim(),
            correct_answer: this.state.quiz.subquestion.answer,
            question_type: 0,
          },
        },
      },
    };
    this.answer = answer;

    for (const [k, v] of Object.entries(json)) {
      if (v == "product_code") delete json[k];
    }
    this.answers.push(json);
  }
  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, answer] of this.answers.entries()) {
      if (answer.id == this.state.quiz.id) {
        this.answers.splice(index, 1);
        break;
      }
    }
  };

  shareInpageClick = (e) => {
    window.document.getElementById("sharehint-dialog").style.display = "block";
    // this.shareHintPage.className = "sharehint-dialog show"
  };
  showResultPage() {
    return function (rsp) {
      if (this.props.thinking_exam_type == 1) {
        return;
      }

      const data = rsp?.data;
      console.log("正确返回");
      console.log(data);

      if (!data?.courses && data?.redirect_url) {
        window.location.href = data.redirect_url;
        return;
      }

      let objR = new Object();
      data?.courses.forEach(function (item) {
        objR[item.course_id] = true;
      });

      this.setState({
        data: data,
        list: objR,
        isSubmitAnswer: true,
        examSubjectDisplay: "none",
      });
      if (UserAgent.isWebBrowser()) {
        window.onpopstate = function () {
          promptPageBack("GRADEEXAMPushStack").clear();
        };
      }
    };
    // console.log(this.state.data)
  }

  // 连错提交， 查看结果
  handleSubmitContinusWrong = () => {
    if (!!this.state.testResultRedirectUrl) {
      ToastManager.showLoading("页面跳转中....");
      window.location.href = this.state.testResultRedirectUrl;
    }
  };

  submitGradeExamAnswers = () => {
    console.log("costTimeGroup", this.costTimeGroup);
    var quizzes = {
      quizzes: this.answers,
      total_time: this.totalCostTime,
      ...this.calculateCorrectness.call(this, this.answers),
    };
    if (this.props.thinking_exam_type == 1) {
      return retryFetch()(async () => {
        let self = this;
        const resp = await request({
          url: self.props.save_result_url,
          method: "POST",
          headers: csrfHeaders,
          data: quizzes,
        });
        const result = resp.data;
        const url = result.redirect_url;
        if (!!url) {
          ToastManager.showLoading("请稍等....");
          window.location.href = url;
        }
      });
    } else {
      return retryFetch()(() => {
        let self = this;
        return request({
          url: self.props.save_result_url,
          method: "POST",
          headers: csrfHeaders,
          data: quizzes,
        });
      });
    }
  };

  startExamClick = () => {
    this.initRecoverRecord.call(
      this,
      this.props.quizzes,
      this.showCountDownTime
    );
    this.setState({
      startExam: true,
    });
  };

  // 实时获取倒计时百分比
  getCountTime = ((self) => {
    let cachedCount = 100;
    return (secs) => {
      let { is_answer_time_limited, count_down_secs, quizzes } = self.props;
      let quizTime = is_answer_time_limited
        ? Math.ceil(count_down_secs / quizzes.length)
        : count_down_secs;

      let seconds = (secs / (quizTime - 1)) * 100;
      if (cachedCount !== seconds) {
        cachedCount = seconds;
        self.setState({
          singleQuizCountTime: seconds,
        });
      }
    };
  })(this);

  initSignleQuizTime = () => {
    let singleQuizTime = 0;
    const countDownTotalTime = this.props.count_down_secs;
    const quizzesLength = this.props.quizzes.length;
    if (quizzesLength > 0) {
      singleQuizTime = countDownTotalTime / quizzesLength;
    }
    const today = new Date();
    const singleQuizTimeStamp = today.setSeconds(
      today.getSeconds() + singleQuizTime + 1
    );
    this.setState({
      singleQuizTimeStamp,
    });
  };

  showCountDownTime = () => {
    const isAnswerTimeLimited = this.props.is_answer_time_limited;
    const countDownTotalTime = this.props.count_down_secs;

    this.setState({
      startAnswerTimer: true,
    });

    if (!isAnswerTimeLimited && countDownTotalTime > 0) {
      const today = new Date();
      const countDownTime = today.setMinutes(
        today.getMinutes() + countDownTotalTime / 60
      );
      this.setState({
        countDownTime,
      });
    } else {
      this.initSignleQuizTime();
    }
  };

  checkResultClick = (e) => {
    if (!!this.props.report_url) {
      window.location.href = this.props.report_url;
      return;
    }
    const { courses, redirect_url } = this.props.result ?? {};
    if (!courses && redirect_url) {
      window.location.href = redirect_url;
    } else {
      this.setState({
        resultDisplay: true,
        startExam: true,
        examSubjectDisplay: "none",
      });
    }
  };

  checkAgainTest = (e) => {
    this.setState({
      resultDisplay: false,
      startExam: false,
      examSubjectDisplay: "block",
    });
  };

  handleCountdownEnd = (type) => {
    const normalCountDownEnd = () => {
      if (this.state.isSubmitAnswer == true) {
        return;
      }
      const buttons = [
        {
          label: "确定",
          onClick: () => {
            this.confirmDialog.close();
            this.submitAnswersAndshowResult.call(this);
          },
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>本次考试时间已到</Dialog>
      );
    };
    const singleQuizCountDownEnd = () => {
      const toast = ToastManager.showToastText("答题时间到");
      const setIntervalTimeAlert = () => {
        return new Promise((resolve) => {
          this.setState(
            (preState) => ({
              timeAlert: preState.timeAlert - 1,
            }),
            resolve
          );
        });
      };
      this.setState(() => ({
        isShowTimeAlert: true,
        timeAlert: 2,
      }));
      this.showTimeInterval = setInterval(() => {
        setIntervalTimeAlert().then(() => {
          if (this.state.timeAlert < 1) {
            this.isSubmitting = false;
            this.setState(
              () => ({
                isSelectedOrFilled: true,
              }),
              () => {
                this.initTimeOut();
                toast.cancel();
                if (this.state.quiz.question_type === 1) {
                  $("#confirm_button").trigger("click");
                  this.initSignleQuizTime();
                  return;
                }
                if (this.state.quiz.question_type === 0) {
                  $("#fill-btn-submit").trigger("click");
                  this.initSignleQuizTime();
                  return;
                }
              }
            );
          }
        });
      }, 1000);
    };

    const handler = {
      SINGLE_QUIZ: singleQuizCountDownEnd,
      WHOLE_QUIZ: normalCountDownEnd,
    };

    return handler[type];
  };

  getResultData = () => {
    const { data, isSubmitAnswer, resultDisplay } = this.state;
    const { result } = this.props;
    if (isSubmitAnswer) {
      return data;
    }
    if (resultDisplay) {
      return result;
    }
  };

  // 获取音频是否正在播放的状态
  getAudioPlayStatus = (boolState) => {
    this.setState(() => ({
      audioPlayState: boolState,
    }));
  };

  subTitle = () => {
    let len = this.props.quizzes.length;
    if (!this.props.count_down_secs) {
      return `${len}题`;
    }

    if (this.props.is_answer_time_limited) {
      let time = this.secondsToHms(Math.ceil(this.props.count_down_secs / len));
      return `${len}题 · 每题限时${time}`;
    } else {
      let time = this.secondsToHms(this.props.count_down_secs);
      return `${len}题 · 限时${time}`;
    }
  };

  secondsToHms = (d) => {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    let hDisplay = h > 0 ? h + "小时" : "";
    let mDisplay = m > 0 ? m + "分" : "";
    let sDisplay = s > 0 ? s + "秒" : "";
    if (s <= 0 && m > 0) {
      mDisplay += "钟";
    }
    return hDisplay + mDisplay + sDisplay;
  };

  componentDidMount() {
    if (this.state.isSubmitAnswer) {
      let resultPage = document.getElementById("result_page_box");
      resultPage.style.cssText =
        `width:100%;` +
        `height:100%;` +
        "background: white;" +
        `display: block;`;
      let container = document.getElementsByClassName("container")[0];
      container.style.cssText = "background: white;";
    }
  }
  componentWillUnmount() {
    let container = document.getElementsByClassName("container")[0];
    container.style.cssText = "background: #f9f7ef;";
  }

  coverDescription() {
    const { title, desc, interval_state, result, report_url } = this.props;
    const subTitle = this.subTitle();
    return (
      <div className="grade_exam_content">
        <div className="grad_exam_start title">{title}</div>
        <div className="grad_exam_start subTitle">{subTitle}</div>
        <div className="describe_button">
          <p className="grade-exam-start-description">{desc}</p>
          {interval_state <= 0 ? (
            <button
              className="grade-exam-start-btn"
              onClick={this.startExamClick}
            >
              开始测评
            </button>
          ) : (
            <p className="interval_state_tip">
              您已经完成测试，<b>{interval_state}</b> 天后可再次测试。
            </p>
          )}
          {(!!report_url || result) && (
            <button
              className="grade-exam-check-result-btn"
              onClick={this.checkResultClick}
            >
              查看结果
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      quiz_audio_enable,
      count_down_secs,
      interval_state,
      result,
    } = this.props;
    const {
      isSelectedOrFilled,
      quiz,
      selectedIndex,
      startExam,
      isSubmitAnswer,
      isSuccDisplayQuizImages,
      resultDisplay,
      countDownTime,
      isShowTimeAlert,
      singleQuizTimeStamp,
      imgDialogStatus,
      singleQuizCountTime,
      setCanScrollIcon,
      audioPlayState,
      startAnswerTimer,
      answerText,
      continueWrongCountArrive,
      // kbDimensionEleStatus
    } = this.state;

    const options = this.state.quiz.subquestion.options;
    const index = this.props.quizzes.findIndex(function (element) {
      return element.id === quiz.id;
    });

    let isOptionsImageExit;
    if (quiz.subquestion.options) {
      isOptionsImageExit = quiz.subquestion.options.some((option) => {
        return option.photo;
      });
    }
    // console.log("GradeExam: ",isOptionsImageExit);
    const poetry_exam_rank = [
      dialog_star_0,
      dialog_star_1,
      dialog_star_2,
      dialog_star_3,
    ];

    const resultData = this.getResultData();
    // const resultData = kbDimensionEleStatus ? false : this.getResultData();
    // console.log('kbDimensionEleStatus: ', kbDimensionEleStatus);
    // console.log('resultData: ', resultData);

    return (
      <div
        className={`comp-grade-exam ${
          count_down_secs ? "has-countdown-exam" : "no-countdown-exam"
        }`}
        id="comp_grade_exam"
        ref={(comp) => (this.compGrade = comp)}
      >
        {
          <div
            className="grade-exam-cover"
            style={{ display: !this.state.startExam ? "block" : "none" }}
          >
            <div className="exam_section">
              <div className="grade_exam_top" />
              {this.coverDescription()}
              <div className="grade_exam_bottom">
                <div className="photo_section">
                  <img src={comp_home_left} alt="" />
                  <img src={comp_home_right} alt="" />
                </div>
              </div>
            </div>
          </div>
        }
        {startExam && (
          <div>
            {!this.props.count_down_secs || this.props.count_down_secs <= 0
              ? ""
              : !resultData && (
                  <div className="clock_fixed_top">
                    <div className="progress_bar_content">
                      <div className="clock_count_down">
                        <div className="progress_bar">
                          <div
                            className="progress"
                            style={{ width: singleQuizCountTime + "%" }}
                          />
                          <div className="text_progress" />
                        </div>

                        {
                          // 连错成立，答题终止，停止计时
                          continueWrongCountArrive ? (
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                color: "#807140",
                              }}
                            >
                              终止计时
                            </span>
                          ) : (
                            !isShowTimeAlert &&
                            !isSubmitAnswer && (
                              <CountDownTimeBlock
                                singleQuizTime={singleQuizTimeStamp}
                                countDownTime={countDownTime}
                                handleCountdownEnd={this.handleCountdownEnd}
                                getCountTime={this.getCountTime}
                                key={
                                  this.props.is_answer_time_limited
                                    ? index + 1
                                    : null
                                }
                                count_down_secs={this.props.count_down_secs}
                                is_answer_time_limited={
                                  this.props.is_answer_time_limited
                                }
                                quizzes={this.props.quizzes}
                                audioPlayState={audioPlayState}
                                startAnswerTimer={startAnswerTimer}
                              />
                            )
                          )
                        }
                      </div>

                      {/*<div className="left_clock">*/}
                      {/*  <img src={icon_clock} alt="" />*/}
                      {/*</div>*/}
                    </div>
                  </div>
                )}
            <div style={{ display: this.state.examSubjectDisplay }}>
              <div
                className={`generate_quiz_box ${
                  this.props.count_down_secs ? "count_down_padding" : ""
                } ${
                  this.props.count_down_secs
                    ? "count_down_generate"
                    : "count_down_bottom"
                }`}
                style={{
                  margin: startExam
                    ? this.props.count_down_secs
                      ? "35px 0 10px"
                      : ""
                    : "",
                  // 如果根据是否有计时判断顶部倒计时显示隐藏，这里会有样式跳动问题
                  // margin: (!isShowTimeAlert && !isSubmitAnswer) ? "45px 0 10px" : ""
                }}
              >
                {startExam && setCanScrollIcon && <SetCanScrollIcon />}
                <div className="question_img_container">
                  <div
                    className="quiz_and_read_quiz"
                    style={{
                      height: quiz.question_audio_url ? "50px" : "",
                      // marginTop: (quiz.question_audio_url && this.props.count_down_secs) ? "-30px" : ""
                    }}
                  >
                    {quiz.question_audio_url && quiz_audio_enable && (
                      <QuizVoice
                        audioUrl={quiz.question_audio_url}
                        quizId={quiz.id}
                        getAudioPlayStatus={this.getAudioPlayStatus}
                      />
                    )}
                    <div className="quiz_length new_quiz_length">
                      <div className="quiz_number">
                        <i>{`第${index + 1}题`}</i>/
                        {`共${this.props.quizzes.length}题`}
                      </div>
                    </div>
                  </div>
                  <div className="question_and_photo">
                    {quiz.question && (
                      <div className="quiz_content_box">{quiz.question}</div>
                    )}
                    <ShowQuestionPhotoUrl
                      isSuccDisplay={isSuccDisplayQuizImages}
                      questionPhotoUrl={quiz.question_photo_url}
                      reloadQuizImages={this.reloadQuizImages}
                      handleImgDialog={() => this.handleImgDialog(true)}
                    />
                  </div>
                  {imgDialogStatus && (
                    <ImgDialog
                      photo_url={quiz.question_photo_url}
                      handleImgDialog={() => this.handleImgDialog(false)}
                    />
                  )}
                  <audio
                    src="https://upload.fireflybaby.cn/failure1.mp3"
                    id="effect_fail_sound"
                    preload="auto"
                  />
                  <audio
                    src="https://upload.fireflybaby.cn/pass.mp3"
                    id="effect_pass_sound"
                    preload="auto"
                  />
                  {/* {console.log("-----------", quiz)} */}
                  {quiz.question_body && (
                    <div className="question_body">
                      <div className="markdown_content question_body_content">
                        <ReactMarkdown
                          source={quiz.question_body}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {this.state.quiz.question_type === 1 ? (
                  <ShowOptionsArea
                    withoutWrapper={true}
                    isSuccDisplay={isSuccDisplayQuizImages}
                    options={options}
                    isSelectedOrFilled={isSelectedOrFilled}
                    selectedIndex={selectedIndex}
                    handleSelect={this.handleSelect}
                    isOptionsImageExit={isOptionsImageExit}
                    confirmButton={() =>
                      startExam && !isSubmitAnswer && !resultDisplay ? (
                        <div className="confirm_button_box option_confirm_box">
                          <div
                            className="confirm_button"
                            id="confirm_button"
                            onClick={debounce(this.confirmSelect)}
                            style={{
                              background: isSelectedOrFilled
                                ? "#49c114"
                                : "lightgrey",
                            }}
                          >
                            确定
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  />
                ) : (
                  <ShowInputArea
                    isSuccDisplay={isSuccDisplayQuizImages}
                    answerText={answerText}
                    isSelectedOrFilled={isSelectedOrFilled}
                    handleInputChange={this.handleInputChange}
                    btnSubmitFillAnwer={this.btnSubmitFillAnwer}
                    confirmButton={() => (
                      <div className="confirm_button_box fill_button_box">
                        <button
                          className="fill-btn-submit"
                          onClick={this.btnSubmitFillAnwer}
                          style={{
                            background: isSelectedOrFilled
                              ? "#49c114"
                              : "lightgrey",
                            margin: "10px 5%",
                          }}
                        >
                          确定
                        </button>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {
          !!resultData ? (
            this.props.study_mode == "intelligent" &&
            this.props.entry_test &&
            !this.state.resultDisplay ? (
              <>
                {resultData.score < 80 && !this.state.showFraction && (
                  <div className=" back-fixed">
                    <div className="no-pass-back">
                      <div className="no-pass-tiitle">
                        <div>{` 学前测试未通过，联系`}</div>
                        <div>{`课程老师，更换/取消课程吧~`}</div>
                      </div>
                      <div className="no-pass-content">
                        <img
                          src={icon_module_border}
                          className="icon_module_border"
                        ></img>
                        <div className="no-pass-content-small"></div>
                        <div className="no-pass-content-big">
                          <img
                            src={this.props.cc_qrcode_url}
                            className="code-img"
                          ></img>
                          <div className="module-button">
                            <img
                              src={this.props.teacher_photo}
                              className="photo-img"
                            ></img>
                            <div className="module-line">
                              {this.props.teacher_name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="test-no-cancle"
                        onClick={() => {
                          window.location.href = this.props.study_url;
                          // window.localStorage.showFraction = true;
                          // this.setState({
                          //   showFraction: true,
                          // });
                        }}
                      >
                        <img src={icon_cancle_upper}></img>
                      </div>
                    </div>
                  </div>
                )}
                {resultData.score >= 80 && !this.state.showFraction && (
                  <div className="back-fixed">
                    <div className="pass-back">
                      <div className="fraction-position">
                        <div>
                          <span className="fraction">{resultData.score}</span>
                          <span className="fraction-font">{"分"}</span>
                        </div>
                        <div>恭喜你通过入学测试！</div>
                      </div>
                      <div className="test-pass-title">
                        去设置你的上课时间吧~
                      </div>
                      <div className="test-pass-bottom">
                        <RenderButtom
                          text="去设置"
                          handleClick={() => {
                            window.location.href = this.props.setting_url;
                          }}
                          answer={true}
                          icon_img={course_right_white}
                        ></RenderButtom>
                      </div>
                      <div
                        className="test-pass-cancle"
                        onClick={() => {
                          // window.localStorage.showFraction = true;
                          window.location.href = this.props.study_url;
                        }}
                      >
                        <img src={icon_cancle_upper}></img>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <ShowResultList
                result={resultData}
                course_type={this.props.course_type}
                poetry_exam_rank={poetry_exam_rank}
                result_desc={this.props.result_desc}
                renderShareContent={() =>
                  !this.props.bought && (
                    <ShareContent shareInpageClick={this.shareInpageClick} />
                  )
                }
                renderResultListFooter={() =>
                  ResultListFooter({
                    isSubmitAnswer,
                    resultDisplay,
                    result: resultData,
                    reviewExamClick: this.checkAgainTest,
                  })
                }
              />
            )
          ) : (
            ""
          )
          // : (
          //   // 最后一题提交,链接中的最后一项匹配, 弹框提醒_回退
          //   resultData == false && (
          //     <div className="ge_kb_dimension_dialog">
          //       <div className="dimension_dialog_box">
          //         <p>测试已完成，返回维度详情页</p>
          //         <a href="/kb_dimensions">确定</a>
          //       </div>
          //     </div>
          //   )
          // )
        }
        {continueWrongCountArrive && (
          <ContinusWrongModal
            quizStage={quiz.product_code}
            handleSubmitContinusWrong={this.handleSubmitContinusWrong}
          />
        )}
      </div>
    );
  }
}

export default class GradeExam extends React.Component {
  constructor(props) {
    super(props);
    const rewardOptions = {
      totalPoint: props.baby_point,
      shouldReward: props.should_reward,
      createRewardPath: props.create_reward_path,
      studyPackageActivityId: props.study_package_activity_id,
      allCorrectRewardPoint: props.all_correct_reward_point,
      finishWholeDayStudyRewardPoint: props.finish_whole_day_study_reward_point,
      studyOnTimePoint: props.study_on_time_reward_point,
      showStudyOnTime: props.study_on_time_reward,
    }
    this.wrappedGradeExam = BasicQuizGeneration(GradeExamItem, "GRADE_EXAM", rewardOptions);
  }
  componentDidMount() {
    const share_image_url = this.props.share.image_url;
    const share_title = this.props.share.title;
    const share_desc = this.props.share.desc;
    const url = this.props.share.url;
    wx.ready(function () {
      function on_share_success() {
        window.document.getElementById("sharehint-dialog").style.display =
          "none";
        window.document.getElementById("wechat_share_box").style.display =
          "none";
        // this.shareHintPage.className = "sharehint-dialog hide"
        // this.wechatShareBox.className = "wechat-share-box hide"
      }
      var sharedJson = {
        title: share_title,
        link: url,
        desc: share_desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack("GRADEEXAMPushStack", "要退出本次练习吗？").setPrompt();
    }

    // 从链接中判断 “kb_dimension=cover” 是否存在, 决定模拟题封面图要不要显示
    const kb_sign = location.href;
    console.log("kb_sign: ", kb_sign.includes("kb_dimension=cover"));
    if (kb_sign.includes("kb_dimension=cover")) {
      $(".grade-exam-start-btn").trigger("click");
    }
  }

  render() {
    const WrappedGradeExam = this.wrappedGradeExam;
    return <WrappedGradeExam {...this.props} />;
  }
}
