import React from "react";
import PropTypes from "prop-types";
import UserAgent from "../utils/UserAgent";

import "./Interview.scss";

const HeadTopKnowledge = ({ handleReportUrlClick }) => {
  return (
    <div className="exam_top_knowledge" onClick={handleReportUrlClick}>
      <p>历年面试真题</p>
      <span>7个维度，110个考点</span>
    </div>
  );
};

const SchoolTestEmphases = ({
  handleTestEmphasesClick,
  schoo_test_emphases,
}) => {
  return (
    <div className="school_test_emphases">
      {schoo_test_emphases.map((emphases, idx) => (
        <div
          className="emphases_name"
          key={idx}
          data-url={emphases.url}
          onClick={handleTestEmphasesClick}
        >
          {emphases.name}
        </div>
      ))}
    </div>
  );
};

const MultipleTestExams = ({ handleTestExamsClick, exam_lists }) => {
  return (
    <div className="multiple_test_exam">
      <ul>
        {exam_lists.map((exam, index) => (
          <li key={index} onClick={handleTestExamsClick} data-url={exam.url}>
            <div className="exam_name">{exam.name}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default class Interview extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    const schoo_test_emphases = [
      {
        name: "学校考点",
        url: this.props.interview_school_url,
      },
      {
        name: "错题本",
        url: this.props.wrong_quiz_url,
      },
    ];

    this.state = {
      schoo_test_emphases,
    };
  }

  componentDidMount = () => {
    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    var title = this.props.title;
    var desc = "还有基于真题测评结果的针对性练习题~";
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  };

  handleTestExamsClick = (e) => {
    let url = e.currentTarget.getAttribute("data-url");
    this.userAgentUrlClick(url);
  };

  handleTestEmphasesClick = (e) => {
    let url = e.currentTarget.getAttribute("data-url");
    this.userAgentUrlClick(url);
  };

  handleReportUrlClick = (e) => {
    const report_url = this.props.dimension_examinations[0].report_url;
    this.userAgentUrlClick(report_url);
  };

  userAgentUrlClick = (url) => {
    // console.log(url)
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  render() {
    const { schoo_test_emphases } = this.state;
    const { exam_lists } = this.props;

    return (
      <div className="comp-interview">
        <div className="interview-exam-wrapper">
          <HeadTopKnowledge handleReportUrlClick={this.handleReportUrlClick} />
          <SchoolTestEmphases
            handleTestEmphasesClick={this.handleTestEmphasesClick}
            schoo_test_emphases={schoo_test_emphases}
          />
          <MultipleTestExams
            handleTestExamsClick={this.handleTestExamsClick}
            exam_lists={exam_lists}
          />
        </div>
      </div>
    );
  }
}

Interview.propTypes = {
  dimension_examinations: PropTypes.array,
  schools: PropTypes.array,
  quizzes: PropTypes.array,
};
