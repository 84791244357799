// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/arrow-up.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/arrow-down.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".comment_tags_content{padding:15px;font-size:12px;background:#fff}.comment_tags_content .tags{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-content:space-between;align-content:space-between;margin-left:-4px;margin-right:-4px}.comment_tags_content .tags.tags_mini{max-height:68px;overflow:hidden}.comment_tags_content .tags li{margin:4px;padding:0 10px;font-size:12px;line-height:2;color:#333;border:1px solid #ccc;border-radius:12px}.comment_tags_content .tags li.active{color:#ff5f3f;background:#fff2f1;border:1px solid #ff5f3f}.comment_tags_content .tags li.cutomized_comment_active{color:#92b7f6;background:#fff;border:1px solid #92b7f6}.comment_tags_content .comment_tag_arrow{width:100%;height:24px;background-size:12px auto;background-repeat:no-repeat;background-position:center 50%;cursor:pointer}.comment_tags_content .comment_tag_arrow.comment_tag_arrow_up{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.comment_tags_content .comment_tag_arrow.comment_tag_arrow_down{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
module.exports = exports;
