import React from "react";
import PropTypes from "prop-types";

import "./InterviewReport.scss";
import passImg from "icon_quiz_pass.png";
import failImg from "icon_quiz_fail.png";
import doubtImg from "icon_quiz_doubt.png";
import arrowRight from "arrow-right.png";

import { request, csrfHeaders } from "../utils/request";
import UserAgent from "../utils/UserAgent";
import { ToastManager } from "../utils/Toast";
import Raven from "raven-js";
import InterviewClearSection from "../utils/interview_clear/InterviewClearSection";

export default class InterviewReport extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    let tabList = Array(this.props.sub_reports.length).fill(false);
    this.props.sub_reports.forEach(function (sub, index) {
      if (sub.active) {
        tabList[index] = true;
      }
    });

    // console.log("tabList修改前", tabList)
    if (
      sessionStorage.getItem("current_index") &&
      sessionStorage.getItem("current_index") > 0
    ) {
      tabList[sessionStorage.getItem("current_index")] = true;
      tabList[0] = false;
      // console.log("修改后tabList", tabList)
    }

    this.state = {
      sub_reports: this.props.sub_reports,
      tabList: tabList,
      examDisplay: "block",
    };
  }

  componentDidMount = () => {
    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    var title = "幼升小面试真题 | 2017-2011年上海数学思维面试真题汇编";
    var desc = "还有基于真题测评结果的针对性练习题~";
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    let get_item_current_index = sessionStorage.getItem("current_index");
    let get_item_sub_report_url = sessionStorage.getItem("sub_report_url");

    if (sessionStorage.getItem("current_index")) {
      this.ajaxRequest.call(
        this,
        get_item_sub_report_url,
        get_item_current_index
      );
    }

    let widthValue = 0;
    if (document.getElementById("tab_title")) {
      for (let i = 0; i < get_item_current_index; i++) {
        widthValue +=
          document.getElementById("tab_title").children[i].offsetWidth + 25;
      }

      this.tabList.scrollLeft = widthValue;
    }
  };

  handleUpDown = (e) => {
    e.preventDefault();
    let url = e.currentTarget.getAttribute("data-url");
    // console.log(url)
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  handleTabClick = (e) => {
    let index = e.currentTarget.getAttribute("data-tab");
    let sub_report_url = e.currentTarget.getAttribute("data-request-url");

    let temp = this.state.tabList;
    temp.fill(false);
    temp[index] = true;

    this.setState(() => ({
      tabList: temp,
    }));

    sessionStorage.setItem("current_index", index);
    sessionStorage.setItem("sub_report_url", sub_report_url);

    this.ajaxRequest.call(this, sub_report_url, index);
  };

  ajaxRequest(url, index) {
    if (this.state.sub_reports[index].media_courses.length != 0) {
      return;
    } else {
      this.setState({
        examDisplay: "none",
      });
      this.toast = ToastManager.showLoading("加载中...");
      request({
        url: url,
        method: "GET",
        headers: csrfHeaders,
      })
        .then((res) => {
          this.toast.cancel();
          const data = res.data;
          console.log(data);
          let interview_clear_url = data.interview_clear_url;

          if (
            data.media_courses.length != 0 ||
            data.exam_url ||
            data.exam_title
          ) {
            this.state.sub_reports[index].media_courses = data.media_courses;
            this.state.sub_reports[index].exam_url = data.exam_url;
            this.state.sub_reports[index].exam_title = data.exam_title;
            this.setState({
              sub_reports: this.state.sub_reports,
              examDisplay: "block",
              interview_clear_url,
            });
          }
        })
        .catch((err) => {
          this.toast.cancel();
          Raven.captureException(err);
        });
    }
  }

  render() {
    const { sub_reports, interview_clear_url, tabList } = this.state;

    let session_current_index = sessionStorage.getItem("current_index");
    let session_current_index_exist =
      session_current_index && sub_reports[session_current_index].title;
    let sub_report_title = session_current_index_exist
      ? sub_reports[session_current_index].title
      : sub_reports[0].title;
    let clear_url = interview_clear_url
      ? interview_clear_url
      : sub_reports[0].interview_clear_url;

    return (
      <div className="comp_interview_report">
        <div
          className="tab_title"
          id="tab_title"
          ref={(tab) => (this.tabList = tab)}
        >
          {sub_reports.map((subReport, ix) => (
            <div
              onClick={this.handleTabClick}
              data-tab={ix}
              data-request-url={subReport.sub_report_url}
              key={ix}
              className={tabList[ix] ? "active" : ""}
            >
              {subReport.title}
            </div>
          ))}
        </div>
        {
          // 页面数据是从这里开始的
          sub_reports.map((sub_report, index) => (
            <div
              className="report_wrapper"
              key={index}
              style={{ display: this.state.tabList[index] ? "block" : "none" }}
            >
              <div>
                {!sub_report.media_courses
                  ? ""
                  : sub_report.media_courses.map((media_course) => (
                      <div key={media_course.id} className="media_course_list">
                        <div className="media_course_name">
                          {media_course.name}
                          {media_course.grade &&
                            "（" + media_course.grade + "水平）"}
                        </div>
                        {media_course.media_lessons.map((lesson, idx) => (
                          <div
                            className="knowledge_item_report"
                            key={lesson.id}
                            href="#"
                          >
                            <a
                              className="knowledge_item_title"
                              onClick={this.handleUpDown}
                              data-url={lesson.url}
                              href="javascript:void(0)"
                            >
                              <span>
                                {lesson.passed == true ? (
                                  <img src={passImg} />
                                ) : lesson.passed == false ? (
                                  <img src={failImg} />
                                ) : (
                                  <img src={doubtImg} />
                                )}
                                <span>
                                  {lesson.name}（{lesson.real_quiz_count}
                                  道真题）
                                </span>
                              </span>
                              <img src={arrowRight} />
                            </a>
                          </div>
                        ))}
                      </div>
                    ))}
              </div>
              {(sub_report.media_courses
                ? sub_report.media_courses.length > 0
                : sub_reports[0].media_courses.length > 0) && (
                <InterviewClearSection
                  title={sub_report_title}
                  interview_clear_url={clear_url}
                  text={"重做本单元题目 »"}
                />
              )}
            </div>
          ))
        }
      </div>
    );
  }
}

InterviewReport.propTypes = {
  sub_reports: PropTypes.array,
  share_url: PropTypes.string,
  share_image_url: PropTypes.string,
};
