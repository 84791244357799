import React, { useEffect } from "react";
import { debounce } from "../../utils/helpers";
import Tips from "Tips.png";
import Result from "Result.png";
import styled from "styled-components";

const ContinusWrongDialog = styled.div`
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  padding: 0px;
  .coutinus_wrong_box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: #fff;
    border-radius: 8px;
  }
`;

const ImgText = styled.div`
  background-color: #fdf5f4;
  text-align: center;
  padding: 5px;
  border-radius: 8px 8px 0 0;
  img {
    display: block;
    margin: 15px auto 5px;
    width: 80px;
  }
  .test_end_title {
    color: #f00;
  }
  p {
    color: #888;
    text-align: center;
    font-size: 12px;
    span {
      color: #f00;
    }
  }
`;
const ButtonBox = styled.div`
  background-color: #fff;
  margin: 10px;
  a {
    display: block;
    max-width: 50%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    background-color: #49c114;
    margin: 0 auto;
    border-radius: 50px;
  }
`;

const ContinusWrongModal = ({ quizStage, handleSubmitContinusWrong }) => {
  useEffect(() => {
    document.querySelector("html, body").classList.add("no_scroll");
    return () => {
      document.querySelector("html, body").classList.remove("no_scroll");
    };
  }, []);
  return (
    <ContinusWrongDialog>
      <div className="coutinus_wrong_box">
        <ImgText>
          <img src={Tips} alt="" />
          <div className="test_end_title">测评结束</div>
          <p>
            {quizStage}阶段题目已答错 <span>5</span> 题，本次测评结束
          </p>
        </ImgText>
        <ButtonBox onClick={debounce(() => handleSubmitContinusWrong())}>
          <a href="javascript:void(0)">查看结果</a>
        </ButtonBox>
      </div>
    </ContinusWrongDialog>
  );
};

export default ContinusWrongModal;
