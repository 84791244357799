import React, { Component } from "react";
import PropTypes from "prop-types";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import { debounce } from "../utils/helpers";
import UserAgent from "../utils/UserAgent";
import { request, csrfHeaders } from "../utils/request";
import "./SchoolMediaLesson.scss";

const HeadTitle = ({ exam_type, quizzes }) => {
  return (
    <div className="exam_guide_text">
      {exam_type === "SCHOOL_MEDIA_LESSON" ? "模拟题" : "历年面试真题"}
      {quizzes && `（${quizzes.length}题）`}
    </div>
  );
};

const ReportRelateSchool = ({ schools }) => {
  return (
    <div className="report_relate_school">
      <h3 className="title">以下学校在面试中考过</h3>
      <div className="report_relate_content">
        {schools.map((school, s) => (
          <div className="school_name" key={s}>
            {school.name ? school.name : ""}
          </div>
        ))}
      </div>
    </div>
  );
};

const KnowledgeItemDes = ({ url, conclusion }) => {
  return (
    <div className="knowledge_item_des">
      <h3 className="title">知识点讲解</h3>
      <div className="knowledge_desc_content">
        {conclusion && <LazyloadContent content={conclusion} />}
        {url && (
          <a className="video_intensive" href={url}>
            <span>5分钟视频精讲</span>
          </a>
        )}
      </div>
    </div>
  );
};

const NextExam = ({ handleNextUrlClick, next_media_lesson_name }) => {
  return (
    <div className="next_exam" onClick={handleNextUrlClick}>
      <div className="next_step_text">下一考点：</div>
      <div className="content">{next_media_lesson_name}</div>
    </div>
  );
};

const QuizSelectOptionSubject = ({
  quiz,
  answerShowStatus,
  handleSelect,
  isSelectedOrFilled,
  selectedIndex,
}) => {
  return (
    <div className="options_wrapper">
      {quiz.subquestions &&
        quiz.subquestions[0].options.map((option, ix) => (
          <div
            key={ix}
            onClick={quiz.user_answer || answerShowStatus ? null : handleSelect}
            className={`options ${
              quiz.user_answer || answerShowStatus
                ? null
                : isSelectedOrFilled && selectedIndex == ix
                ? "selected"
                : ""
            }`}
            data-option={option.correct}
            data-content={option.content}
            data-photo={option.photo && option.photo.url}
            data-index={ix}
          >
            {option.photo ? <img src={option.photo.url} /> : option.content}
          </div>
        ))}
    </div>
  );
};

const FillInputSubject = ({
  quiz,
  answerShowStatus,
  answerText,
  handleInputChange,
}) => {
  return (
    <div>
      {!quiz.user_answer && !answerShowStatus && (
        <div className="exam-fill-subject">
          <input
            type="tel"
            placeholder="请输入答案"
            value={answerText}
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  );
};

const QuizConfirmButton = ({
  quiz,
  answerShowStatus,
  handleConfirmClick,
  isSelectedOrFilled,
}) => {
  return (
    <div>
      {!quiz.user_answer && !answerShowStatus && (
        <div className="report_answer">
          <div
            className="button_answer"
            onClick={debounce(handleConfirmClick)}
            style={{ background: isSelectedOrFilled ? "#49c114" : "lightgrey" }}
          >
            确定
          </div>
        </div>
      )}
    </div>
  );
};

export default class SchoolMediaLesson extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  handleNextUrlClick = (e) => {
    e.preventDefault();

    const url = this.props.next_media_lesson_url;
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  render() {
    const {
      quizzes,
      exam_type,
      exam_progress_save_url,
      schools,
      media_lesson,
      next_media_lesson_url,
      next_media_lesson_name,
    } = this.props;

    return (
      <div className="comp_school_media_lessons">
        {quizzes.length > 0 && (
          <div className="exam_quiz_box">
            <HeadTitle exam_type={exam_type} quizzes={quizzes} />
            {quizzes.map((quiz, idx) => (
              <AnswerQuestionSection
                key={idx}
                quiz={quiz}
                idx={idx}
                examType={exam_type}
                exam_progress_save_url={exam_progress_save_url}
              />
            ))}
          </div>
        )}

        {schools && schools.length > 0 && (
          <ReportRelateSchool schools={schools} />
        )}

        <KnowledgeItemDes
          conclusion={media_lesson.conclusion}
          url={media_lesson.url}
        />

        {next_media_lesson_url && (
          <NextExam
            handleNextUrlClick={this.handleNextUrlClick}
            next_media_lesson_name={next_media_lesson_name}
          />
        )}
      </div>
    );
  }
}

class AnswerQuestionSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quiz: this.props.quiz,
      answerText: "",
      selectedIndex: false,
      isSelectedOrFilled: false,
      answerShowStatus: false,
      needAnsweredStatus: false,
    };
  }

  // 处理答案选择操作
  handleSelect = (e) => {
    const { examType } = this.props;
    let selectTarget = e.currentTarget;
    let selectedIndex = selectTarget.getAttribute("data-index");
    this.startAnswerTime = new Date();
    // console.log(this.startAnswerTime)

    if (
      examType === "SCHOOL_MEDIA_LESSON" ||
      examType === "INTERVIEW_MEDIA_LESSON"
    ) {
      this.setState(
        (preState) => ({
          isSelectedOrFilled: true,
          selectedIndex: selectedIndex,
          isSelectedOption: selectTarget.getAttribute("data-option"),
          isSelectedContent: selectTarget.getAttribute("data-content"),
          isSelectedPhoto: selectTarget.getAttribute("data-photo"),
          selectedOption: preState.quiz.subquestions[0].options[selectedIndex],
        }),
        () => {
          // console.log('selected_option', this.state.selectedOption)
          // console.log('is_selected_or_filled', this.state.isSelectedOrFilled)
          // console.log('selected_index', this.state.selectedIndex)
          // console.log('is_selected_option', this.state.isSelectedOption)
          // console.log('is_selected_content', this.state.isSelectedContent)
          // console.log('is_selected_photo', this.state.isSelectedPhoto)
        }
      );

      let correctOption = [];
      let correctOptionPhoto = [];
      let correctContent = "";
      let correctPhoto = "";
      this.state.quiz.subquestions[0].options.forEach((option) => {
        if (option.correct == true) {
          correctOption.push(option);
          correctOptionPhoto.push(option.photo);
          correctContent = option.content;
          option.photo && (correctPhoto = option.photo.url);
        }
        // console.log("correct_option,", correctOption)
        // console.log("correct_option[0],", correctOption[0])
      });

      this.setState(() => ({
        correctOption: correctOption[0],
        correctContent,
        correctOptionPhoto,
        correctPhoto,
      }));
    }
  };

  // 处理选择题确定
  confirmSelect = () => {
    if (!this.state.isSelectedOrFilled) {
      return;
    }

    // 选择的内容： this.state.isSelectedContent
    // 正确的内容： this.state.correctContent

    // 选择的图片： this.state.isSelectedPhoto
    // 正确的图片： this.state.correctPhoto

    // Ajax
    const { exam_progress_save_url, examType } = this.props;
    const media_lesson_id = this.state.quiz.media_lesson.id;
    const question_type = this.state.quiz.subquestions[0].question_type;
    const { isSelectedOption, correctOption, selectedOption } = this.state;
    this.endAnswerTime = new Date();

    request({
      url: exam_progress_save_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        answer: {
          id: media_lesson_id,
          exam_type: examType,
          start_answer_time: this.startAnswerTime,
          end_answer_time: this.endAnswerTime,
          media_lesson: {
            tag: {
              quiz: {
                id: this.state.quiz.id,
                input_correct: isSelectedOption,
                choose_option: selectedOption,
                correct_option: correctOption,
                question_type: question_type,
              },
            },
          },
        },
      },
    })
      .then((resp) => {
        // console.log("resp: ", resp)
        this.setState({
          answerShowStatus: true,
          needAnsweredStatus: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  haveUserAnswerResult(userAnswerCorrect) {
    return userAnswerCorrect ? (
      <div className="answer_correct">回答正确</div>
    ) : (
      <div className="answer_error">回答错误</div>
    );
  }

  currentUserDirectResponse(needAnsweredStatus, questionType, fillEqual) {
    return needAnsweredStatus ? (
      questionType === 1 ? (
        this.state.isSelectedOption === "true" ? (
          <div className="answer_correct">回答正确</div>
        ) : (
          <div className="answer_error">回答错误</div>
        )
      ) : fillEqual ? (
        <div className="answer_correct">回答正确</div>
      ) : (
        <div className="answer_error">回答错误</div>
      )
    ) : null;
  }

  questionQuestion(quiz, noAnswerClassName) {
    return (
      <div
        className={`option_title ${
          quiz.user_answer ? "have_user_answer" : noAnswerClassName
        }`}
      >
        <p>{quiz.question}</p>
      </div>
    );
  }

  questionPhoto(quiz) {
    return quiz.question_photo_url != null ? (
      <div className="question_pic">
        <img src={quiz.question_photo_url} />
      </div>
    ) : null;
  }

  // 填空题输入答案操作
  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    this.startAnswerFillime = new Date();

    this.setState(
      () => ({
        isSelectedOrFilled: true,
        answerText: value,
      }),
      () => {
        if (this.state.answerText.trim() == "") {
          this.setState({
            isSelectedOrFilled: false,
          });
        }
      }
    );
  };

  // 处理填空题确定
  btnSubmitFillAnwer = () => {
    if (!this.state.isSelectedOrFilled) {
      return;
    }

    const { exam_progress_save_url, examType } = this.props;
    const { answerText } = this.state;

    const media_lesson_id = this.state.quiz.media_lesson.id;
    const question_type = this.state.quiz.subquestions[0].question_type;
    const correct_answer = this.state.quiz.subquestions[0].answer;
    this.endAnswerFillTime = new Date();

    request({
      url: exam_progress_save_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        answer: {
          id: media_lesson_id,
          exam_type: examType,
          start_answer_time: this.startAnswerFillime,
          end_answer_time: this.endAnswerFillTime,
          media_lesson: {
            tag: {
              quiz: {
                id: this.state.quiz.id,
                input_correct: answerText.trim() == correct_answer,
                question_type: question_type,
                input_answer: answerText.trim(),
                correct_answer: correct_answer,
              },
            },
          },
        },
      },
    })
      .then((resp) => {
        // console.log("resp: ", resp)
        this.setState({
          answerShowStatus: true,
          needAnsweredStatus: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { quiz, idx } = this.props;

    let { isSelectedOrFilled, selectedIndex, needAnsweredStatus } = this.state;
    let questionType = quiz.subquestions[0].question_type;
    const noAnswerClassName = !needAnsweredStatus ? "no_have_user_answer" : "";

    let userAnswerCorrect;
    let user_answer_choose_option;
    let user_answer_correct_option;
    if (quiz.user_answer) {
      userAnswerCorrect = quiz.user_answer.subquestions[0].correct === true;

      if (questionType === 1) {
        user_answer_choose_option =
          quiz.user_answer.subquestions[0].choose_option;
        user_answer_correct_option =
          quiz.user_answer.subquestions[0].correct_option;
      }
    }

    const fillEqual =
      this.state.answerText.trim() == quiz.subquestions[0].answer;

    let userAnswerChooseContent;
    let userAnswerCorrectContent;
    if (questionType === 1 && quiz.user_answer) {
      if (user_answer_choose_option.correct === true) {
        userAnswerChooseContent = user_answer_choose_option.content;
      } else {
        userAnswerChooseContent = user_answer_choose_option.content;
      }
      if (user_answer_correct_option.correct === true) {
        userAnswerCorrectContent = user_answer_correct_option.content;
      }
    }
    return (
      <div className="answer_question_section">
        <div className="practice_report_box" key={quiz.id}>
          <div className="index">{`${idx + 1}.`}</div>
          {quiz.user_answer
            ? this.haveUserAnswerResult.call(this, userAnswerCorrect)
            : this.currentUserDirectResponse.call(
                this,
                needAnsweredStatus,
                questionType,
                fillEqual
              )}
          <div className="practice_report_info">
            {this.questionQuestion.call(this, quiz, noAnswerClassName)}
            {this.questionPhoto.call(this, quiz)}
            {
              // 选择题
              questionType === 1 ? (
                <QuizSelectOptionSubject
                  quiz={quiz}
                  answerShowStatus={this.state.answerShowStatus}
                  handleSelect={this.handleSelect}
                  isSelectedOrFilled={isSelectedOrFilled}
                  selectedIndex={selectedIndex}
                />
              ) : (
                <FillInputSubject
                  quiz={quiz}
                  answerShowStatus={this.state.answerShowStatus}
                  answerText={this.state.answerText}
                  handleInputChange={this.handleInputChange}
                />
              )
            }
          </div>

          {
            // "确定" 按钮
            <QuizConfirmButton
              quiz={quiz}
              answerShowStatus={this.state.answerShowStatus}
              handleConfirmClick={
                questionType === 1
                  ? this.confirmSelect
                  : this.btnSubmitFillAnwer
              }
              isSelectedOrFilled={isSelectedOrFilled}
            />
          }

          {
            // 选择的内容： this.state.isSelectedContent
            // 正确的内容： this.state.correctContent

            // 选择的图片： this.state.isSelectedPhoto
            // 正确的图片： this.state.correctPhoto

            questionType === 1 ? (
              // 选择题结果
              quiz.user_answer ? (
                <QuizHaveUserAnswer
                  user_answer_choose_option={user_answer_choose_option}
                  user_answer_correct_option={user_answer_correct_option}
                  userAnswerChooseContent={userAnswerChooseContent}
                  userAnswerCorrectContent={userAnswerCorrectContent}
                />
              ) : (
                this.state.answerShowStatus && (
                  <QuizUserDirectResponse
                    isSelectedOption={this.state.isSelectedOption}
                    isSelectedPhoto={this.state.isSelectedPhoto}
                    isSelectedContent={this.state.isSelectedContent}
                    correctOptionPhotoValue={this.state.correctOptionPhoto[0]}
                    correctContent={this.state.correctContent}
                  />
                )
              )
            ) : // 填空题结果
            quiz.user_answer ? (
              <FillHaveUserAnswer
                userAnswerCorrect={userAnswerCorrect}
                isCorrectAnswer={
                  quiz.user_answer.subquestions[0].correct_answer
                }
                isUserFillAnswer={quiz.user_answer.subquestions[0].input_answer}
              />
            ) : (
              this.state.answerShowStatus && (
                <FillUserDirectResponse
                  isAnswerText={this.state.answerText.trim()}
                  isCorrectAnswer={quiz.subquestions[0].answer}
                />
              )
            )
          }
        </div>
      </div>
    );
  }
}

const QuizHaveUserAnswer = ({
  user_answer_choose_option,
  userAnswerChooseContent,
  user_answer_correct_option,
  userAnswerCorrectContent,
}) => {
  return (
    <div className="report_answer">
      <div
        className={
          user_answer_choose_option.correct === true
            ? "respond_answer"
            : "answer_fail"
        }
      >
        <p className="title">回答：</p>
        {user_answer_choose_option.photo ? (
          <img src={user_answer_choose_option.photo.url} />
        ) : (
          <p className="text">{userAnswerChooseContent}</p>
        )}
      </div>
      <div className="right_answer">
        <p className="title">正确答案：</p>
        {user_answer_correct_option.photo || user_answer_choose_option.photo ? (
          <img
            src={
              user_answer_correct_option.photo.url ||
              user_answer_choose_option.photo.url
            }
          />
        ) : (
          <p className="text">{userAnswerCorrectContent}</p>
        )}
      </div>
    </div>
  );
};

const QuizUserDirectResponse = ({
  isSelectedOption,
  isSelectedPhoto,
  isSelectedContent,
  correctOptionPhotoValue,
  correctContent,
}) => {
  return (
    <div className="report_answer">
      <div
        className={
          isSelectedOption === "true" ? "respond_answer" : "answer_fail"
        }
      >
        <p className="title">回答：</p>
        {isSelectedPhoto ? (
          <img src={isSelectedPhoto} />
        ) : (
          <p className="text">{isSelectedContent}</p>
        )}
      </div>
      <div className="right_answer">
        <p className="title">正确答案：</p>
        {correctOptionPhotoValue ? (
          <img src={correctOptionPhotoValue.url} />
        ) : (
          <p className="text">{correctContent}</p>
        )}
      </div>
    </div>
  );
};

const FillHaveUserAnswer = ({
  userAnswerCorrect,
  isCorrectAnswer,
  isUserFillAnswer,
}) => {
  return (
    <div className="report_answer">
      <div
        className={
          userAnswerCorrect === true ? "respond_answer" : "answer_fail"
        }
      >
        <p className="title">回答：</p>
        <p className="text">{isUserFillAnswer}</p>
      </div>
      <div className="right_answer">
        <p className="title">正确答案：</p>
        <p className="text">{isCorrectAnswer}</p>
      </div>
    </div>
  );
};

const FillUserDirectResponse = ({ isAnswerText, isCorrectAnswer }) => {
  return (
    <div className="report_answer">
      <div
        className={
          isAnswerText === isCorrectAnswer ? "respond_answer" : "answer_fail"
        }
      >
        <p className="title">回答：</p>
        {<p className="text">{isAnswerText}</p>}
      </div>
      <div className="right_answer">
        <p className="title">正确答案：</p>
        {<p className="text">{isCorrectAnswer}</p>}
      </div>
    </div>
  );
};

SchoolMediaLesson.propTypes = {
  exam_progress_save_url: PropTypes.string,
  exam_type: PropTypes.string.isRequired,
  media_lesson: PropTypes.object.isRequired,
  quizzes: PropTypes.array.isRequired,
  schools: PropTypes.array.isRequired,
};
