import React from "react";
import PropTypes from "prop-types";

import UserAgent from "../utils/UserAgent";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { debounce, promptPageBack } from "../utils/helpers";
import { DialogGrade, DialogGradeManager } from "../utils/DialogGrade";
import CountDownTime from "../utils/CountDown";
import { Dialog, DialogManager } from "../utils/Dialog";
import { BasicQuizGeneration } from "../day_studies/BasicQuizGeneration";
import {
  ShowQuestionPhotoUrl,
  ImgDialog,
  ShowOptionsArea,
} from "../utils/quizzes_practice_template/practiceTemplates.jsx";
import dialogPass from "dialog-xy-pass.png";
import dialogDoubt from "dialog-xy-doubt.png";
import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";
import "../day_studies/GenerateQuiz.scss";

class InterviewExamItem extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    // var countDownTime = 0
    var quiz = props.quizzes[0];
    this.state = {
      quiz: quiz,
      save_result_url: props.save_result_url,
      answers: [],
      effectSoundURL: "",
      startExam: false,
      isSuccDisplayQuizImages: true,
      imgDialogStatus: false,
      // countDownTime: countDownTime
    };
    this.answer = {};
    this.answers = [];
    // this.handleSelect = this.handleSelect.bind(this)
    this.startExamClick = this.startExamClick.bind(this);
    // this.confirmSelect = this.confirmSelect.bind(this);
  }

  // handleSelect(e) {
  //   var el = e.target
  //   if (el.nodeName === 'LI' || el.nodeName==='IMG') {
  //     if (el.nodeName === 'IMG') {
  //       el = el.parentNode
  //     }
  //     let index = parseInt(el.getAttribute('data-index'))
  //     let option = this.state.quiz.subquestion.options[index]
  //     var childs = el.parentNode.childNodes
  //     for(var i = 0; i < childs.length; i++) {
  //       let node = childs[i]
  //       let nodeID = node.getAttribute('id')
  //       if (nodeID == option.id) {
  //         // el.className = option.correct ? 'correct' : 'error'
  //         // if (option.correct) {
  //         //   this.setState({
  //         //     effectSoundURL: 'https://upload.fireflybaby.cn/pass.mp3'
  //         //   }, () => {
  //         //     this.effectSound.play()
  //         //   })
  //         // } else {
  //         //   this.setState({
  //         //     effectSoundURL: 'https://upload.fireflybaby.cn/failure1.mp3'
  //         //   }, () => {
  //         //     this.effectSound.play()
  //         //   })
  //         // }

  //       } else {
  //         // node.className = ''
  //       }
  //     }
  //     var json = {
  //       id: this.state.quiz.id,
  //       subquestions: [
  //         {
  //           id: this.state.quiz.subquestion.id,
  //           option: [
  //             { id: option.id}
  //           ]
  //         }
  //       ],
  //       correct: option.correct
  //     }
  //     var answers = this.state.answers
  //     answers.push(json)
  //     this.setState({
  //       answers: answers
  //     })
  //     this.toast = ToastManager.showLoading("提交中...")
  //     var lastQuiz = this.props.quizzes[this.props.quizzes.length-1]
  //     if (lastQuiz && lastQuiz.id == this.state.quiz.id) {
  //       setTimeout(this.submitAnswer.bind(this), 500)
  //     } else {
  //       setTimeout(this.nextQuiz.bind(this), 500)
  //     }
  //   }
  // }
  handleSelectedAnswerData(correctOption, option) {
    let userAnswer = { ...option };
    var answer = {
      id: this.props.dimension_examination.id,
      exam_type: this.props.exam_type,
      media_lesson: {
        tag: {
          quiz: {
            id: this.state.quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        },
      },
    };
    var json = {
      id: this.state.quiz.id,
      subquestions: [
        {
          id: this.state.quiz.subquestion.id,
          option: [{ id: userAnswer.id }],
        },
      ],
      correct: userAnswer.correct,
    };
    this.answer = answer;
    this.answers.push(json);
  }
  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, answer] of this.answers.entries()) {
      if (answer.id == this.state.quiz.id) {
        this.answers.splice(index, 1);
        break;
      }
    }
  };
  // nextQuiz() {
  //   this.toast.cancel()
  //   var quiz = this.state.quiz
  //   var index = this.props.quizzes.findIndex(function(element){
  //     return element.id == quiz.id
  //   })
  //   var nextQuiz = this.props.quizzes[index+1]
  //   console.log('下一题', nextQuiz)
  //   this.setState({
  //     quiz: nextQuiz
  //   })
  // }

  shareInpageClick = (e) => {
    window.document.getElementById("sharehint-dialog").style.display = "block";
    // this.shareHintPage.className = "sharehint-dialog show"
  };

  showResult = (result) => {
    const buttons = [];
    buttons.push({
      label: "重考",
      onClick: () => {
        this.confirmDialog.close();
        const whatGui = document.getElementsByClassName("comp-grade-exam")[0];
        if (whatGui) {
          whatGui.style.cssText = "display:none";
        }
        // window.location.reload(true)
      },
    });
    this.confirmDialog = DialogGradeManager.open(
      <DialogGrade buttons={buttons}>
        <span className="score_tip">本次得分</span>
        <span className="score">{result.score}</span>
        <div className="grade_exam_list">
          <ul>
            {result.courses.map((course) => {
              return (
                <li
                  key={course.course_id}
                  className={course.correct ? "icon_pass" : "icon_fail"}
                >
                  {course.course_name}
                </li>
              );
            })}
          </ul>
        </div>
      </DialogGrade>
    );
    if (UserAgent.isWebBrowser()) {
      window.onpopstate = function () {
        promptPageBack("INTERVIEWEXAMPushStack").clear();
      };
    }
  };

  submitInterviewExamAnswers = () => {
    var data = {
      quizzes: this.answers,
      total_time: this.totalCostTime,
      ...this.calculateCorrectness.call(this, this.answers),
    };
    console.log(data);
    console.log(this.state.save_result_url);
    return request({
      url: this.state.save_result_url,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    });
  };
  showResultPage() {
    return function (rsp) {
      console.log(rsp);
      const buttons = [];
      buttons.push({
        label: "查看相应课程",
        onClick: () => {
          this.setState({
            startExam: false,
          });
          window.location.href = rsp.data.redirect_url;
          this.confirmDialog.close();
        },
      });
      this.confirmDialog = DialogGradeManager.open(
        <DialogGrade buttons={buttons}>
          <span className="score_tip">本次得分</span>
          <span className="score">{rsp.data.score}</span>
          <div className="grade_exam_list">
            <ul>
              {rsp.data.courses.map((course) => {
                return (
                  <li
                    key={course.course_id}
                    className={course.correct ? "icon_pass" : "icon_fail"}
                  >
                    {course.course_name}
                  </li>
                );
              })}
            </ul>
          </div>
        </DialogGrade>
      );
    };
  }
  componentDidMount() {
    const share_image_url = this.props.share.image_url;
    const share_title = this.props.share.title;
    const share_desc = this.props.share.desc;
    var url = window.location.href;
    wx.ready(function () {
      function on_share_success() {
        window.document.getElementById("sharehint-dialog").style.display =
          "none";
        window.document.getElementById("wechat_share_box").style.display =
          "none";
        // this.shareHintPage.className = "sharehint-dialog hide"
        // this.wechatShareBox.className = "wechat-share-box hide"
      }
      var sharedJson = {
        title: share_title,
        link: url,
        desc: share_desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }

  startExamClick() {
    var countDownTime = 0;
    if (this.props.count_down_mins) {
      var today = new Date();
      console.log(today.getMinutes() + this.props.count_down_mins);
      countDownTime = today.setMinutes(
        today.getMinutes() + this.props.count_down_mins
      );
    }
    this.setState({
      startExam: true,
      countDownTime: countDownTime,
    });
    this.initRecoverRecord.call(this, this.props.quizzes);
  }

  checkResultClick = (e) => {
    this.showResult(this.props.result);
  };

  handleCountdownEnd = (e) => {
    const buttons = [
      {
        label: "确定",
        onClick: () => {
          this.confirmDialog.close();
          this.submitAnswersAndshowResult.call(this);
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>本次考试时间已到</Dialog>
    );
  };

  render() {
    const { quiz_audio_enable } = this.props;
    const options = this.state.quiz.subquestion.options;
    const {
      isSelectedOrFilled,
      quiz,
      startExam,
      selectedIndex,
      isSuccDisplayQuizImages,
      imgDialogStatus,
    } = this.state;
    const index = this.props.quizzes.findIndex(function (element) {
      return element.id == quiz.id;
    });
    let isOptionsImageExit;
    if (quiz.options) {
      isOptionsImageExit = quiz.options.some((option) => {
        return option.photo;
      });
    }
    return (
      <div
        className="comp-interview-exam"
        id="comp_grade_exam"
        ref={(comp) => (this.compGrade = comp)}
      >
        <div
          className="grade-exam-start"
          style={{ display: !this.state.startExam ? "block" : "none" }}
        >
          <div className="grade_exam_top"></div>
          <div className="grade_exam_content">
            <h2 className="grad_exam_start_title">{this.props.title}</h2>
            <div className="describe_button">
              <p className="grade-exam-start-description">{this.props.desc}</p>
              <button
                className="grade-exam-start-btn"
                onClick={this.startExamClick}
              >
                开始
              </button>
              {this.props.result && (
                <button
                  className="grade-exam-check-result-btn"
                  onClick={this.checkResultClick}
                >
                  查看答案
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="quiz_and_read_quiz">
          {quiz.question_audio_url && quiz_audio_enable && (
            <QuizVoice audioUrl={quiz.question_audio_url} quizId={quiz.id} />
          )}
          <div className="quiz_length new_quiz_length">
            {this.state.countDownTime > 0 ? (
              <CountDownTime
                endTime={new Date(this.state.countDownTime)}
                overText="结束考试"
                onEnd={this.handleCountdownEnd}
              >
                {({ d, h, m, s }) => {
                  return (
                    <span>
                      {h}:{m}:{s}
                    </span>
                  );
                }}
              </CountDownTime>
            ) : (
              ""
            )}
            <div className="quiz_number">
              <i>{`第${index + 1}题`}</i>/{`共${this.props.quizzes.length}题`}
            </div>
          </div>
        </div>
        <div className="question_and_photo">
          {quiz.question && (
            <div className="quiz_content_box">{quiz.question}</div>
          )}
          <ShowQuestionPhotoUrl
            isSuccDisplay={isSuccDisplayQuizImages}
            questionPhotoUrl={quiz.question_photo_url}
            reloadQuizImages={this.reloadQuizImages}
            handleImgDialog={() => this.handleImgDialog(true)}
          />
        </div>
        {imgDialogStatus && (
          <ImgDialog
            photo_url={quiz.question_photo_url}
            handleImgDialog={() => this.handleImgDialog(false)}
          />
        )}
        <audio
          src={this.state.effectSoundURL}
          ref={(effectSound) => (this.effectSound = effectSound)}
        ></audio>

        <ShowOptionsArea
          withoutWrapper={true}
          isSuccDisplay={isSuccDisplayQuizImages}
          options={options}
          isSelectedOrFilled={isSelectedOrFilled}
          selectedIndex={selectedIndex}
          handleSelect={this.handleSelect}
          isOptionsImageExit={isOptionsImageExit}
          confirmButton={() =>
            startExam ? (
              <div className="confirm_button_box">
                <div
                  className="confirm_button"
                  onClick={debounce(this.confirmSelect)}
                  style={{
                    background: isSelectedOrFilled ? "#49c114" : "lightgrey",
                  }}
                >
                  确定
                </div>
              </div>
            ) : (
              ""
            )
          }
        />
      </div>
    );
  }
}

export default class InterviewExam extends React.Component {
  constructor(props) {
    super(props);
    this.wrappedGradeExam = BasicQuizGeneration(
      InterviewExamItem,
      "INTERVIEW_EXAM"
    );
  }
  componentDidMount() {
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack(
        "INTERVIEWEXAMPushStack",
        "要退出本次练习吗？"
      ).setPrompt();
    }
  }
  render() {
    const WrappedGradeExam = this.wrappedGradeExam;
    return <WrappedGradeExam {...this.props} />;
  }
}
InterviewExam.propTypes = {
  quiz: PropTypes.object,
  save_result_url: PropTypes.string,
};
