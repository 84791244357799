import React from "react";
import { request, csrfHeaders } from "../../utils/request";
import { showDialog } from "../../utils/helpers";
import Raven from "raven-js";
import "./InterviewClearSection.scss";

export default class InterviewClearSection extends React.Component {
  handleClearSubject = () => {
    let { title, interview_clear_url } = this.props;
    let dialogOptions = {
      text: `重新做题会清空《${title}》当前的做题纪录，确定要重做吗？`,
      mainLabel: "取消",
      subLabel: "确定重做",
      mainCallback: () => {},
      subCallback: () => {
        request({
          url: interview_clear_url,
          method: "POST",
          headers: csrfHeaders,
        })
          .then((resp) => {
            // const data = resp.data
            window.location.reload();
          })
          .catch((err) => {
            Raven.captureException(err);
          });
      },
    };
    showDialog.call(this, dialogOptions);
  };
  render() {
    let { title, text } = this.props;
    return (
      <div className="interview_clear_section">
        <div className="renewal_subject" onClick={this.handleClearSubject}>
          {text}
        </div>
        <p>注意：重新做题会清空《{title}》当前的做题纪录</p>
      </div>
    );
  }
}
