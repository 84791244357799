import React from "react";
import PropTypes from "prop-types";
import Uri from "jsuri";

import { ToastManager } from "../utils/Toast";
import AuthenticationDialogProxy from "../shared/AuthenticationDialog";
import { pay } from "../utils/pay";
import { request, csrfHeaders } from "../utils/request";

import UserAgent from "../utils/UserAgent";

export default class MemberCardRecharge extends React.Component {
  onBuySuperCardClick = (e) => {
    this.buySuperCard();
  };

  buySuperCard() {
    this.toast = ToastManager.showLoading("加载中...");
    request({
      url: this.props.super_cards_buy_url,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((resp) => {
        this.toast.cancel();

        const data = resp.data;
        if (data.redirect_url) {
          if (UserAgent.isNativeClient()) {
            XinYaNativeBridge.navigate({ href: data.redirect_url });
          } else {
            window.location.href = data.redirect_url;
          }
          return;
        }
        if (data.err_code) {
          switch (data.err_code) {
            case "err_unauthenticated":
              new AuthenticationDialogProxy(data.redirect_url).open();
              break;
          }
          return;
        }

        if (data.errno) {
          alert("网络异常，请重新尝试");
          return;
        }

        pay(data);
      })
      .catch((err) => {
        this.toast.cancel();
      });
  }

  // onItemClick = option => {
  //   this.recharge(option.count)
  // }

  recharge(url, params) {
    this.toast = ToastManager.showLoading("加载中...");
    console.log("购买链接 MemberCardRecharge：" + url);
    console.log("购买参数 MemberCardRecharge：", params);
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        this.toast.cancel();

        const data = resp.data;
        if (data.redirect_url) {
          if (UserAgent.isNativeClient()) {
            XinYaNativeBridge.navigate({ href: data.redirect_url });
          } else {
            window.location.href = data.redirect_url;
          }
          return;
        }
        if (data.err_code) {
          switch (data.err_code) {
            case "err_unauthenticated":
              new AuthenticationDialogProxy(data.redirect_url).open();
              break;
          }
          return;
        }

        if (data.errno) {
          alert("网络异常，请重新尝试");
          return;
        }

        var params = Object.assign(data, {
          callback: (isSuccess, res) => {
            let gift_commodities_url = this.props.gift_commodities_url;
            if (gift_commodities_url) {
              window.location.href = gift_commodities_url;
              return;
            }
          },
        });

        if (UserAgent.isWebBrowser()) {
          params["toast"] = this.toast;
        }

        pay(params);
      })
      .catch((err) => {
        this.toast.cancel();
      });
  }
}

MemberCardRecharge.propTypes = {
  // recharge_url: PropTypes.string.isRequired,
  // recharge_options: PropTypes.array.isRequired
};
