import React from "react";
import PropTypes from "prop-types";

import fail from "icon_quiz_fail.png";
import pass from "icon_quiz_pass.png";

import title_interview_pass from "interview_exams/title_interview_pass.png";
import flag from "interview_exams/flag.png";
import result_doubt from "interview_exams/result_doubt.png";
import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";

import "./InterviewResult.scss";

export default class InterviewResult extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      score: 0,
      list: Array(this.props.school_results).fill(true),
    };
  }

  componentDidMount = () => {
    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    var title = "幼升小面试真题 | 2017-2011年上海数学思维面试真题汇编";
    var desc = "还有基于真题测评结果的针对性练习题~";
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    setTimeout(() => {
      this.setState({
        score: this.props.score,
      });
    }, 0);
  };

  handleUpDown = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    this.state.list[idx] = !this.state.list[idx];
    this.setState({
      list: this.state.list,
    });
  };
  render() {
    const school_results = this.props.school_results;
    const dimension_examinations = this.props.dimension_examinations;

    const { list } = this.state;

    let userScore, scoreProgress_left, scoreProgress_right;
    if (this.state.score < 70) {
      userScore = <span> 还需要继续加油哦～</span>;
    } else if (this.state.score >= 70 && this.state.score <= 90) {
      userScore = <span> 不错，再加把劲～</span>;
    } else {
      userScore = <span> 真棒！</span>;
    }

    if (this.state.score >= 0 && this.state.score <= 50) {
      const rotate = -135 + (18 / 5) * this.state.score;
      scoreProgress_left = {
        transform: `rotate(${rotate}deg)`,
      };
      scoreProgress_right = {
        transform: `rotate(-135deg)`,
      };
    } else {
      const rotate = -135 + (18 / 5) * (this.state.score - 50);
      scoreProgress_left = {
        transform: `rotate(45deg)`,
      };
      scoreProgress_right = {
        transform: `rotate(${rotate}deg)`,
      };
    }

    return (
      <div className="comp-interview-result">
        <div className="interview_score_box">
          <div className="interview_score">
            {this.state.score ? (
              <div className="circleProgress_wrapper">
                <span className="correct_rate">正确率</span>
                <span className="correct_score">
                  <span className="">{this.state.score}</span>
                  <span>%</span>
                </span>
                <div className="wrapper left">
                  <div
                    className="circleProgress leftcircle"
                    style={scoreProgress_left}
                  ></div>
                </div>
                <div className="wrapper right">
                  <div
                    className="circleProgress rightcircle"
                    style={scoreProgress_right}
                  ></div>
                </div>
              </div>
            ) : (
              <div
                className="circleProgress_wrapper"
                style={{ border: "none" }}
              >
                <span className="correct_score">
                  <img src={result_doubt} alt="" />
                </span>
              </div>
            )}

            <div className="flag_scores">
              {this.state.score ? userScore : <span>请先去测试</span>}
              <img src={flag} alt="" />
            </div>
          </div>
        </div>
        <div className="interview_knowledge_box">
          <div className="interview_result_info">
            <div className="color_white">
              根据测评结果，我们为宝宝生成更有针对性的
              <br />
              专属练习题：
            </div>
            <div className="latitude">
              {dimension_examinations.map((dimension_examination, d) => (
                <div className="item_data" key={d}>
                  {dimension_examination.score >= 0 ? (
                    <p className="normal_percent">
                      {dimension_examination.score}
                      <i>%</i>
                    </p>
                  ) : (
                    <div className="result_doubt">
                      <img src={result_doubt} alt="" />
                    </div>
                  )}
                  <p className="normal_title">{dimension_examination.name}</p>
                  <a href={dimension_examination.exam_url} target="_self">
                    {dimension_examination.title}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className="interview_exam_box">
            <div className="exam_test">
              <a href={this.props.report_url} target="_self">
                查看题目讲解报告 »
              </a>
            </div>
          </div>

          <div className="interview_knowledge">
            <img src={title_interview_pass} alt="" />
            {school_results.map((school_result, index) => (
              <div className="interview_knowledge_list" key={school_result.id}>
                <div
                  className="interview_school_title"
                  onClick={this.handleUpDown}
                  data-index={school_result.id}
                >
                  {school_result.selected ? (
                    <img src={list[school_result.id] ? arrowDown : arrowUp} />
                  ) : (
                    <img src={list[school_result.id] ? arrowUp : arrowDown} />
                  )}
                  <h3>{school_result.name}</h3>
                </div>
                {school_result.selected ? (
                  list[school_result.id] ? (
                    ""
                  ) : (
                    <ul>
                      {school_result.media_courses.map((media_course) => (
                        <li key={media_course.id}>
                          <div>
                            {media_course.passed == true ? (
                              <div className="pass">
                                <img src={pass} alt="" />
                              </div>
                            ) : media_course.passed == false ? (
                              <div className="fail">
                                <img src={fail} alt="" />
                                <a href={media_course.study_package_url}>
                                  查看课程
                                </a>
                              </div>
                            ) : (
                              <div className="no_finish_subject">
                                <a href="javascript:void(0)">未测</a>
                              </div>
                            )}
                            <span>{media_course.name}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )
                ) : list[school_result.id] ? (
                  <ul>
                    {school_result.media_courses.map((media_course) => (
                      <li key={media_course.id}>
                        <div>
                          {media_course.passed == true ? (
                            <div className="pass">
                              <img src={pass} alt="" />
                            </div>
                          ) : media_course.passed == false ? (
                            <div className="fail">
                              <img src={fail} alt="" />
                              {media_course.study_package_url ? (
                                <a href={media_course.study_package_url}>
                                  查看课程
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div className="no_finish_subject">
                              <a href="javascript:void(0)">未测</a>
                            </div>
                          )}
                          <span>{media_course.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>

          <div className="interview_desc">
            学校考点通过情况根据测评结果得出 <br />
            仅供参考
          </div>
        </div>
      </div>
    );
  }
}

InterviewResult.propTypes = {
  school_results: PropTypes.array,
};
