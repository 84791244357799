import React from "react";
import PropTypes from "prop-types";

import MemberCardRecharge from "./MemberCardRecharge";
import ComponentProxy from "../core/ComponentProxy";
import CourseCoupons from "../study_package_activities/CourseCoupons";

import "./MemberCardRechargeDialog.scss";

export class MemberCardRechargeDialog extends MemberCardRecharge {
  constructor(props) {
    super(props);
  }

  payUseCoupon = (
    amount,
    group_state,
    selectActivityId,
    ecouponId,
    virtualAccountMoney
  ) => {
    console.log("payUseCoupon 支付参数: ", {
      amount,
      group_state,
      selectActivityId,
      ecouponId,
      virtualAccountMoney,
    });
    let params = {
      amount: amount,
    };
    if (ecouponId) {
      params["ecoupon_id"] = ecouponId;
    }
    if (virtualAccountMoney) {
      params["virtual_account_money"] = virtualAccountMoney;
    }
    console.log("payUseCoupon", params);
    this.recharge(this.props.buy_url, params);
  };

  render() {
    return (
      <div className="comp-member-card-recharge-dialog">
        <div className="tips_box">
          <CourseCoupons
            isCouseCouponDisplay={"block"}
            systemTime={this.props.system_time}
            eCouponData={this.props.ecoupons}
            onClose={this.props.onClose}
            coursePayFinalPrice={this.props.price}
            ecouponsAvailable={this.props.ecoupons_available}
            freeUrl={this.props.free_url}
            courseName={this.props.name}
            payClickAction={this.payUseCoupon}
            virtualAccount={this.props.virtual_account}
          />
          {/* {
            this.props.on_sale ? 
            <div className="tip_activities_logo">
              <img src={IconTipLogo} />
            </div> : ''       
          }

          <div className="tips_main_content" style={{padding: "10px 0 0"}}>
            <div className="point_recharge_intro point_pay_info">
              <div className="recharge_headline pay_title">
                <p>购买支付</p>
                <span>1点=1个讲座/圆桌主题(少数例外)</span>
              </div>

              <OptionItemList 
                onItemClick={this.onItemClick} 
                recharge_options={this.props.recharge_options} 
              />
            </div>

            <div className="aduio_unlimited_listen">
              {/* <a className="btn_submit" herf="javascript:void(0);" onClick={this.onBuySuperCardClick}>立即报名</a>
              <div className="listen_discrible">
                <p>1个月内免费畅听所有讲座/圆桌主题</p>
                <label>
                  限时特惠：<strong>¥100</strong>（原价：<del>¥200</del>）
                </label>
              </div> */}
          {/* <a href="https://xinya.me/study_package_activity_details?id=262">
            <img src={FamousSchoolStation} alt="" />
          </a> */}
        </div>
        <div className="close_btn_right" onClick={this.props.onClose}>
          &times;
        </div>
      </div>
    );
  }
}

MemberCardRechargeDialog.propTypes = Object.assign(
  {},
  MemberCardRecharge.propTypes,
  {
    // super_cards_buy_url: PropTypes.string.isRequired,

    onClose: PropTypes.func,
    on_sale: PropTypes.bool,
  }
);

MemberCardRechargeDialog.defaultPropTypes = {
  on_sale: false,
};

export class MemberCardRechargeDialogProxy extends ComponentProxy {
  constructor(options) {
    super();
    console.log(options);
    this.options = options;
  }

  createComponent() {
    return (
      <MemberCardRechargeDialog
        {...this.options}
        onClose={() => this.cancel()}
      />
    );
  }
}
