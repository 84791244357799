import React, { useMemo, useCallback, useEffect } from "react";
import {
  MyProvider,
  useSelector,
  CreateStore,
} from "../utils/react-global-state";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import ModalManage from "../utils/Modal/ModalManage.jsx";
import LazyloadDimension from "../kb_dimensions/LazyLoadDimension.jsx";
// import { print } from "../utils/helpers";

import "./BuyExamFeature.scss";
import iconArrow from "kb_buy_exam/icon_arrow.png";

const KB_LEVELS = {
  小班: "T1小班阶段",
  中班: "T2中班阶段",
  大班: "T3大班阶段",
  "一年级・上": "T4一年级上学期",
  "一年级・下": "T5一年级下学期",
};

const KB_DEGREES = {
  基础: "课内基础版",
  拓展: "延伸拓展版",
  详细: "详细版",
  超常: "延伸拓展版",
};

const TitleAndDescription = () => {
  const title = useSelector((state) => state.title);
  const desc = useSelector((state) => state.desc);

  const babyGrade = useSelector((state) => state.babyGrade);
  const examType = useSelector((state) => state.examType);
  const stage = useSelector((state) => state.stage);
  // const firstDesc = desc.substr(0, 18);
  // const secondDesc = desc.substr(18);
  return (
    <div className="title_and_desc">
      <p className="title">
        <span className="title_real">
          {examType && <span className="exam_type">{`${examType}测评`}</span>}
          {title}
        </span>
      </p>
      <p className="title sub_title">
        {examType === "专项"
          ? `（${KB_LEVELS[babyGrade] || babyGrade}）`
          : KB_DEGREES[stage]
          ? `（${KB_DEGREES[stage]}）`
          : ""}
      </p>
      <p className="desc">{desc}</p>
    </div>
  );
};
const ImportanceModal = ({
  modalTitle,
  subTitle,
  importanceDetail,
  onModalClose,
}) => {
  useEffect(() => {
    document.querySelector("html, body").classList.add("no_scroll");
    return () => {
      document.querySelector("html, body").classList.remove("no_scroll");
    };
  }, []);
  return (
    <div className="dialog_detail_introduce">
      <div className="dialog_container">
        <div className="title_section">
          <div className="current_title">
            <div className="title">{modalTitle}</div>
            {subTitle ? <p>{subTitle}</p> : ""}
          </div>
        </div>
        <div className="close_button_right" onClick={onModalClose}></div>
        <LazyloadDimension content={importanceDetail} />
      </div>
    </div>
  );
};

const BuyExamFeature = () => {
  const importance = useSelector((state) => state.importance);
  const attention = useSelector((state) => state.attention);
  const importanceDetail = useSelector((state) => state.importanceDetail);
  const name = useSelector((state) => state.name);
  const KBGrade = useSelector((state) => state.KBGrade);
  const babyGrade = useSelector((state) => state.babyGrade);
  const examType = useSelector((state) => state.examType);
  const standalone = useSelector((state) => state.standalone);
  const importanceModal = useMemo(
    () =>
      new ModalManage({
        render: (_, modalClose) => (
          <ImportanceModal
            modalTitle={name}
            importanceDetail={importanceDetail}
            onModalClose={modalClose}
            // 专项才有subTitle
            subTitle={
              examType === "专项" ? `${KBGrade}（${babyGrade}）阶段` : ""
            }
          />
        ),
      }),
    []
  );

  const onImportanceModalOpen = useCallback(() => importanceModal.open(), []);

  return (
    <div className="comp_buy_exam_feature">
      {/* <div className="version">Beta版</div> */}
      {!standalone && <a className="logo" href="/kb_dimensions/analysis"></a>}
      <TitleAndDescription />
      <div className="level_guide">
        <div className="top"></div>
        <div className="bottom"></div>
      </div>
      <div className="important_broad common_broad">
        <div className="title">能力要求</div>
        <div className="content">
          <LazyloadContent content={importance} />
        </div>
        {!!importanceDetail && (
          <div className="importance_modal" onClick={onImportanceModalOpen}>
            <span>查看专业解读</span>
            <img src={iconArrow}></img>
          </div>
        )}
      </div>

      <div className="attention_broad common_broad">
        <div className="divider">
          <div className="item">
            <div className="thin_divider"></div>
          </div>
          <div className="item">
            <div className="thin_divider"></div>
          </div>
        </div>
        <div className="title">注意事项</div>
        <div className="content">
          <LazyloadContent content={attention} />
        </div>
        <div className="fake_background"></div>
      </div>
    </div>
  );
};

const WithProvider = (props) => {
  console.log("props BuyExamFeature: ", props);
  return (
    <MyProvider
      store={
        new CreateStore(
          {
            ...props,
            attention: props.note,
            title: props.base_name,
            desc: props.desc,
            importanceDetail: props.important_detail,
            KBGrade: props.kb_grade,
            examType: props.kb_dimension_cat,
            babyGrade: props.grade,
          },
          () => {}
        )
      }
    >
      <BuyExamFeature />
    </MyProvider>
  );
};

export default WithProvider;
