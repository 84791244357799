import React, { useState, useEffect } from "react";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import { formatAudioTime } from "../utils/formatAudioTime.js";
import Result from "Result.png";
import { ResultQuizzesList } from "./GradeExam";
import "./ThinkingExamReport.scss";

const ThinkingExamReport = ({ ...props }) => {
  console.log("ThinkingExamReport_props: ", props);
  const {
    study_package_categories,
    total_count,
    correct_count,
    not_answered_count,
    total_time,
    recommendation,
    baby,
    url,
  } = props;
  const result = {
    total_count,
    correct_count,
    not_answered_count,
    total_time,
  };

  let objR = new Object();
  study_package_categories && study_package_categories.length
    ? study_package_categories.forEach(function (item) {
        !item.courses
          ? ""
          : item.courses.forEach(function (im) {
              objR[im.course_id] = true;
            });
      })
    : "";
  const [list, setList] = useState(objR);
  const [singleQuizList, setSingleQuizList] = useState("");
  const [quizOptionIndex, setQuizOptionIndex] = useState("");
  const tabClick = (e) => {
    const tabIdx = e.currentTarget.getAttribute("data-id");
    console.log("tabIdx: ", tabIdx);
  };
  const handleUpDown = (e) => {
    e.preventDefault();
    let ind = e.currentTarget.getAttribute("data-idx");
    list[ind] = !list[ind];
    setList(list);
    setSingleQuizList(list[`${ind}`]);
    setQuizOptionIndex(ind);
  };

  return (
    <div className="comp_thinking_exam_report">
      <QuizzesTimes result={result} />
      {recommendation?.recommend_courses &&
        recommendation?.recommend_courses.length > 0 && (
          <RecommendationsCourse
            nickname={baby.nickname}
            recommendCourses={recommendation?.recommend_courses}
          />
        )}
      {study_package_categories && study_package_categories.length > 0 && (
        <div className="quizzes_stage_list">
          <Tabs styles="tabs" defaultAction={tabClick} defaultActiveIndex={0}>
            {study_package_categories.map((menu, index) => (
              <TabPane
                key={index}
                tab={menu.product_code}
                activeStyle="active"
                styles={`tab`}
              >
                <div
                  className="quiz_detail_container"
                  key={`${quizOptionIndex}-${singleQuizList}`}
                >
                  <div className="quiz_stage_desc">
                    <div className="title">{`《${menu.name}》`}</div>
                    <div className="quiz_answer_desc">{`共${
                      menu.quizzes_count
                    }题，答对${menu.correct_count}题，答错${
                      menu.quizzes_count -
                      menu.correct_count -
                      menu.not_answered_count
                    }题，未答${menu.not_answered_count}题`}</div>
                  </div>
                  <ResultQuizzesList
                    result={menu}
                    upDownList={list}
                    handleUpDown={handleUpDown}
                  />
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      )}
      {!!url && (
        <a href={url} className="confirm_exam_report">
          确定
        </a>
      )}
    </div>
  );
};

const QuizzesTimes = ({ result }) => {
  const quizzes_result = [
    {
      label: "总题数",
      emphasis: `${result.total_count}题`,
    },
    {
      label: "答对",
      emphasis: `${result.correct_count}题`,
    },
    {
      label: "答错",
      emphasis: `${
        result.total_count - result.correct_count - result.not_answered_count
      }题`,
    },
    result.not_answered_count != 0
      ? {
          label: "未答",
          emphasis: `${result.not_answered_count}题`,
        }
      : "",
    {
      label: "用时",
      emphasis: `${formatAudioTime(result.total_time)}`,
    },
  ].filter((item) => item != "");
  console.log("quizzes_result: ", quizzes_result);

  const isHasNoAnsewered = quizzes_result
    .map((item) => item.label)
    .indexOf("未答");

  return (
    <div className="quizzes_times">
      <div className="title">心芽数学思维分级测评</div>
      <ul style={isHasNoAnsewered == -1 ? {} : { overflowX: "scroll" }}>
        {quizzes_result.map((item, index) => (
          <li key={index}>
            <div className="label">{item.label}</div>
            <div className="emphasis">{item.emphasis}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const RecommendationsCourse = ({ nickname, recommendCourses }) => {
  return (
    <div className={`recommend_question_body`}>
      <p className="recommendation_text">根据测评结果，推荐{nickname}报名</p>
      <div className="recommend_list">
        {recommendCourses.map((recommend, index) => {
          const isPoetry = recommend.sub_course_type === "poetry";
          const lessonsOrCourseCount = isPoetry
            ? recommend.media_courses_count || 0
            : recommend.media_lessons_count || 0;
          const isShowLabels = !!(
            (recommend.suitable_grades &&
              recommend.suitable_grades.length > 0) ||
            lessonsOrCourseCount ||
            recommend.course_day_count
          );
          return (
            <a
              key={index}
              className="activity_brief_content"
              href={`${recommend.buy_url}`}
            >
              <div className="left_thumbnail_common">
                <img
                  className="lazyload lazyloading"
                  data-src={recommend.recommend_thumb_photo_url}
                />
              </div>
              <div className="right_content_box">
                <div className="content_box">
                  <div className="course_title">{recommend.name}</div>
                  <div className="recomment_brief">
                    {recommend.recommend_brief}
                  </div>
                </div>
                {isShowLabels && (
                  <div className="labels">
                    {!!(lessonsOrCourseCount || recommend.course_day_count) && (
                      <div className="label_left">
                        {!!lessonsOrCourseCount && (
                          <>
                            <span className="lessons">
                              {`${lessonsOrCourseCount}${
                                recommend.isPoetry ? "首诗" : "节课"
                              }`}
                            </span>
                          </>
                        )}

                        {!!(
                          lessonsOrCourseCount && recommend.course_day_count
                        ) && <span className="split">·</span>}

                        {!!recommend.course_day_count && (
                          <span className="days">{`${recommend.course_day_count}天`}</span>
                        )}
                      </div>
                    )}
                    {recommend.suitable_grades &&
                      recommend.suitable_grades.length > 0 && (
                        <div className="label_right">
                          <span className="suit">适合</span>
                          <span className="grades">
                            {recommend.suitable_grades.join(" ")}
                          </span>
                        </div>
                      )}
                  </div>
                )}
                <div className="course_study_progress">
                  <div className="left_progress">
                    {`¥${recommend.price}`}
                    <i className="text_progress">起</i>
                  </div>
                  <div className="right_now_class">查看详情</div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
      <a className="recommend_course_img" href={recommendCourses[0]?.buy_url}>
        <img src={Result} alt="" />
      </a>
    </div>
  );
};

export default ThinkingExamReport;
