import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import "./KbMarkDown.scss";

class LazyloadDimension extends Component {
  imageRenderer = ({ src, alt }) => {
    return <img className="lazyload" data-src={src} alt={alt || ""} />;
  };

  rendererMarkdown = (content) => {
    if (content) {
      const renderers = { image: this.imageRenderer };
      const md = (
        <ReactMarkdown
          className="md"
          source={content}
          renderers={renderers}
          escapeHtml={false}
          skipHtml={false}
        />
      );
      return md;
    } else {
      return "";
    }
  };

  render() {
    const { content } = this.props;
    return (
      <div className="kb_markdown_content">
        {this.rendererMarkdown(content)}
      </div>
    );
  }
}

export default LazyloadDimension;
