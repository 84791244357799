// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/arrow-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/arrow-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".date_head{background:#fff;padding:12px 10px;box-shadow:0 1px 1px rgba(0,0,0,.2);display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}.date_head .hide_url{background:#fff;color:#fff}.date_head a{font-weight:700;font-size:18px}.date_head a i{font-family:Times;font-size:20px}.date_head a:first-child,.date_head a:last-child{font-weight:400;font-size:15px;color:#736857;margin-top:3px}.date_head a:first-child{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:8px;background-position:0;padding-left:15px;margin-left:5px}.date_head a:last-child{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:8px;background-position:100%;padding-right:15px;margin-right:5px}", ""]);
// Exports
module.exports = exports;
