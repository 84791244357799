import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CountDownDate extends Component {
  constructor(props) {
    super();
    this.state = {
      time: {},
      seconds: props.calcTime, // 获取需要倒计时的总时间( 单位: 秒 )
    };
    // console.log('calcTime: ', props.calcTime);

    this.timer = 0;
    this.pause = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.audioPlayState) {
      // 音频正在播放,停止倒计时
      this.pause = false;
    } else {
      // 音频正在播放,倒计时继续
      this.pause = true;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }

  startTimer = () => {
    this.pause = true;

    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.runCountDown, 1000);
    }
  };

  // 取出相应的时分秒
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.floor(divisor_for_seconds);

    let timeSeconds = hours * 3600 + minutes * 60 + seconds;
    // console.log('timeSeconds: ', timeSeconds);

    this.props.getHourMinuteSecond(timeSeconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  };

  // 倒计时时间
  runCountDown = () => {
    if (this.pause == true) {
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      // 倒计时结束了
      if (seconds <= 0) {
        this.props.onEnd();
        clearInterval(this.timer);
      }
    }
  };

  render() {
    const { overText } = this.props;
    const { time, seconds } = this.state;
    const isOver = seconds <= 0;

    let hour = `${time.h == 0 ? "" : `${time.h}时`}`;
    let minute = `${time.m}分`;
    let second = `${time.s}秒`;
    return (
      <div className="count_down_time">
        {isOver ? overText : `${hour}${minute}${second}`}
      </div>
    );
  }
}

CountDownDate.propTypes = {
  onEnd: PropTypes.func,
};
