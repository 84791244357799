/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import "./ReportQuizItem.scss";

export const ReportQuizItem = ({
  quiz,
  iddx,
  needIndex,
  needAnsweredStatus,
}) => {
  const isOptionQuestionType = quiz.subquestions[0].question_type === 1;
  return (
    <div className="practice_new_report_box" key={quiz.id}>
      {needIndex && <div className="index">{`${iddx + 1}.`}</div>}
      {needAnsweredStatus &&
        (quiz.subquestions[0].correct ? (
          <div className="answer_correct">回答正确</div>
        ) : (
          <div className="answer_error">回答错误</div>
        ))}
      <div className="practice_report_info">
        <div className="option_title">
          <p>{quiz.question}</p>
        </div>
        {quiz.question_photo_url != null ? (
          <div className="question_pic">
            <img src={quiz.question_photo_url} />
          </div>
        ) : (
          ""
        )}
        <div className="options_wrapper">
          {quiz.subquestions[0] && isOptionQuestionType
            ? quiz.subquestions[0].options.map((option, ix) => (
                <div className="options" key={ix}>
                  {option.photo ? (
                    <img src={option.photo.url} />
                  ) : (
                    option.content
                  )}
                </div>
              ))
            : ""}
        </div>
      </div>
      {isOptionQuestionType ? (
        <OptionQuestionType
          isAnswerCorrect={quiz.subquestions[0].correct}
          chooseOption={quiz.subquestions[0].choose_option}
          correctOption={quiz.subquestions[0].correct_option}
        />
      ) : (
        <FillQuestionType
          isAnswerCorrect={quiz.subquestions[0].correct}
          correctAnswer={quiz.subquestions[0].correct_answer}
          inputAnswer={quiz.subquestions[0].input_answer}
        />
      )}
    </div>
  );
};

const OptionQuestionType = ({
  isAnswerCorrect,
  chooseOption,
  correctOption,
}) => {
  return (
    <div className="report_answer">
      <div className={isAnswerCorrect ? "respond_answer" : "answer_fail"}>
        <p className="title">回答：</p>
        {"photo" in chooseOption ? (
          <img src={chooseOption.photo.url} />
        ) : (
          <p>{chooseOption.content}</p>
        )}
      </div>
      <div className="right_answer">
        <p className="title">正确答案：</p>
        {"photo" in correctOption ? (
          <img src={correctOption.photo.url} />
        ) : (
          <p>{correctOption.content}</p>
        )}
      </div>
    </div>
  );
};

const FillQuestionType = ({ isAnswerCorrect, inputAnswer, correctAnswer }) => {
  return (
    <div className="report_answer">
      <div className={isAnswerCorrect ? "respond_answer" : "answer_fail"}>
        <p className="title">回答：</p>
        <p>{inputAnswer}</p>
      </div>
      <div className="right_answer">
        <p className="title">正确答案：</p>
        <p>{correctAnswer}</p>
      </div>
    </div>
  );
};
ReportQuizItem.propTypes = {
  quiz: PropTypes.object.isRequired,
  iddx: PropTypes.number.isRequired,
  needIndex: PropTypes.bool,
  needAnsweredStatus: PropTypes.bool,
};
